import React, { useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button, Paper } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import * as consts from "../../consts";

export default function JoinNew() {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.5rem",
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [gameID, setGameID] = useState("");

  return (
    <Paper className={classes.paper}>
      <Grid item container xs={12}>
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h3" className={classes.header}>
            Join New Game
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.section}>
          <TextField
            id="gameIDInput"
            fullWidth={true}
            required={true}
            label="Game ID"
            value={gameID}
            onChange={(event) => {
              setGameID(event.target.value.toUpperCase().replace(/\s+/g, ""));
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && gameID) {
                event.preventDefault();
                history.push(`${consts.PANEL_JOIN}/${gameID}`);
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            disabled={gameID.length == 0}
            fullWidth={true}
            color="secondary"
            variant="outlined"
            onClick={() => {
              if (gameID) {
                history.push(`${consts.PANEL_JOIN}/${gameID}`);
              }
            }}
          >
            Join Now
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
