import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import {
  Grid,
  Typography,
  LinearProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  IconButton,
  CardActions,
  GridList,
  GridListTile,
  GridListTileBar,
  Link,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Badge,
  ListItemSecondaryAction,
} from "@material-ui/core";
import {
  Favorite as VotedIcon,
  FavoriteBorder as NotVotedIcon,
  ChevronRight as ExpandMoreIcon,
  NotInterested as OwnTeamIcon,
  Favorite as HeartIcon,
  FavoriteBorder as NoVotesIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getClues,
  getVotes,
  getCandidates,
  addVote,
  removeVote,
} from "../../store/actions";
import { useHistory, NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";

export default function Votes(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    hearts: {
      textAlign: "right",
      marginBottom: theme.spacing(1),
    },
    teamHeart: {
      color: theme.palette.icons.teamHeart,
    },
    disabled: {
      color: theme.palette.icons.disabled,
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    voted: {
      color: theme.palette.icons.heart,
    },
    notvoted: {
      color: theme.palette.icons.heart,
    },
    categoryWrapper: {
      margin: "auto",
    },
    image: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    cardActions: {
      flexDirection: "row-reverse",
    },
    candidates: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {},
    gridTile: {
      height: "auto !important",
    },
    listItem: {
      height: "auto !important",
    },
    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [clues, setClues] = useState([]);
  const [votes, setVotes] = useState([]);
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getClues(state.game.id, getAccessTokenSilently, dispatch)
        .then((getCluesResult) => {
          setClues(getCluesResult);
          getVotes(state.game.id, getAccessTokenSilently, dispatch)
            .then((getVotesResult) => {
              setVotes(getVotesResult);
              setBusy(false);
            })
            .catch((exception) => {
              setError(
                exception.response && exception.response.data
                  ? exception.response.data
                  : "Error: There was an issue getting your votes."
              );
              setBusy(false);
            });
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the clues."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const renderHearts = () => {
    return (
      <Grid
        item
        xs={12}
        className={classes.hearts}
        aria-label={`You have ${
          state.game.voteLimit - votes.length
        } votes left to issue`}
      >
        {[...Array(state.game.voteLimit)].map((e, index) => {
          if (index < votes.length) {
            return <VotedIcon className={classes.heart} key={index} />;
          } else {
            return <NotVotedIcon className={classes.heart} key={index} />;
          }
        })}
      </Grid>
    );
  };

  const renderClueList = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <List className={classes.list}>
            {clues.map((clue) => {
              let clueVotes = votes.filter((clueVote) => {
                return clueVote.clueID == clue.id;
              });
              let voteCount = clueVotes.length;
              return (
                <LazyLoad key={clue.id}>
                  <Link
                    component={NavLink}
                    to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_VOTES}/${clue.id}`}
                    activeClassName={classes.selected}
                    color="inherit"
                  >
                    <ListItem
                      alignItems="flex-start"
                      className={classes.listItem}
                      disableGutters={true}
                    >
                      {voteCount > 0 ? (
                        <ListItemIcon>
                          <Badge
                            badgeContent={voteCount}
                            color="primary"
                            classes={{ badge: classes.badge }}
                          >
                            <HeartIcon className={classes.heart} />
                          </Badge>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon>
                          <NoVotesIcon className={classes.heart} />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={clue.title} />
                      <ListItemSecondaryAction>
                        <ExpandMoreIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                  <Divider variant="middle" component="li" />
                </LazyLoad>
              );
            })}
          </List>
        </Grid>
      </Grid>
    );
  };

  const renderVoteList = () => {
    return (
      <React.Fragment>
        {renderHearts()}
        {renderClueList()}
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderVoteList()}
    </Grid>
  );
}
