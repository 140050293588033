import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import {
  Grid,
  Typography,
  Paper,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getResource } from "../../store/actions";
import * as consts from "../../consts";

export default function Rules(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "100%",
    },
    progress: {
      width: "100%",
    },
    dates: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [additionalRulesMarkup, setAdditionalRulesMarkup] = useState("");

  useEffect(() => {
    if (state.game && state.game.id) {
      setBusy(true);
      getResource(
        `${consts.BLOB_URL}${state.game.id}/rules/markup.html`.toLowerCase(),
        dispatch
      )
        .then((getResourceResult) => {
          setAdditionalRulesMarkup(getResourceResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the rules."
          );
          setBusy(false);
        });
    }
  }, [state.game]);

  const renderRules = () => {
    let startDate = new Date(state.game.start);
    let endDate = new Date(state.game.end);
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${state.game.id}/banner.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${state.game.id}/banner.jpg`.toLowerCase()}
              alt=""
              className={classes.image}
            />
          </picture>
        </Grid>
        <Grid item xs={12} className={classes.dates}>
          <Typography variant="caption">
            {`${startDate.toDateString()} - ${endDate.toDateString()}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {additionalRulesMarkup && (
            <div
              dangerouslySetInnerHTML={{ __html: additionalRulesMarkup }}
            ></div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Game Settings:</Typography>
          {renderGameSettings()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderGameSettings = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Setting</TableCell>
              <TableCell component="th">Value</TableCell>
              <TableCell component="th">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Vote Limit
              </TableCell>
              <TableCell>{state.game.voteLimit}</TableCell>
              <TableCell>How many votes each player gets</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Team Size Limit
              </TableCell>
              <TableCell>{state.game.teamSizeLimit}</TableCell>
              <TableCell>How many players can be on a team</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Approval Required
              </TableCell>
              <TableCell>
                {state.game.approvalRequired ? "Yes" : "No"}
              </TableCell>
              <TableCell>
                Will uploaded photos require approval from a game administrator
                before being published
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Points Per Clue
              </TableCell>
              <TableCell>{state.game.pointsPerClue}</TableCell>
              <TableCell>
                How many points are awarded for each approved photo
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Points Per Vote
              </TableCell>
              <TableCell>{state.game.pointsPerVote}</TableCell>
              <TableCell>
                How many points are awarded for each vote received
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Penalty Per Clue
              </TableCell>
              <TableCell>{state.game.penaltyPerClue}</TableCell>
              <TableCell>
                How many points are subtracted for each missing clue
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Penalty Per Vote
              </TableCell>
              <TableCell>{state.game.penaltyPerVote}</TableCell>
              <TableCell>
                How many points are subtracted for each unused vote
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy || !state.game || !state.game.id ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        renderRules()
      )}
    </Grid>
  );
}
