import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import * as consts from "../../consts";
import { State } from "../../store/state";
import { Grid, Typography, Link, Paper } from "@material-ui/core";
import {
  ImageSearch as CluesIcon,
  People as TeamIcon,
  Gavel as RulesIcon,
} from "@material-ui/icons";

export default function Welcome(props) {
  const useStyles = makeStyles((theme) => ({
    link: {},
    header: {
      fontSize: "1.5rem",
    },
    section: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "100%",
    },
    tile: {
      margin: theme.spacing(1),
      height: "20vh",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    clues: {
      color: theme.palette.icons.clues,
    },
    team: {
      color: theme.palette.icons.team,
    },
    rules: {
      color: theme.palette.icons.rules,
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);

  const renderNavigation = () => {
    return (
      <Grid item container xs={12}>
        <Grid item xs={12} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_RULES}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.rules}>
              <div>
                <RulesIcon fontSize="large" />
                <Typography variant="body1">Rules</Typography>
              </div>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_CLUES}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.clues}>
              <CluesIcon fontSize="large" />
              <Typography variant="body1">Clues</Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_TEAM}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.team}>
              <TeamIcon fontSize="large" />
              <Typography variant="body1">Team</Typography>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.header} color="secondary">
          Welcome to&nbsp;{state.game.title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <picture>
          <source
            srcSet={`${consts.BLOB_URL}${state.game.id}/banner.webp`.toLowerCase()}
            type="image/webp"
          />
          <img
            src={`${consts.BLOB_URL}${state.game.id}/banner.jpg`.toLowerCase()}
            alt=""
            className={classes.image}
          />
        </picture>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="body1">
          You have just joined&nbsp;{state.game.title}. You can start by
          checking out the game's rules or if you already know how to play start
          adding people to your team or checkout the clues.
        </Typography>
      </Grid>
      {renderNavigation()}
    </Grid>
  );
}
