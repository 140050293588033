import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const useStyles = makeStyles((theme) => ({
    button: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading || isAuthenticated) {
    return null;
  } else {
    return (
      <Grid item container xs={12}>
        <Grid item xs={12} sm={6} className={classes.button}>
          <Button
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              });
            }}
          >
            Sign In
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.button}>
          <Button
            fullWidth={true}
            color="secondary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
                screen_hint: "signup",
              });
            }}
          >
            Sign up
          </Button>
        </Grid>
      </Grid>
    );
  }
}
