import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link, Button, Hidden } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as consts from "../../consts";

export default function ErrorMessage({ message }) {
  const useStyles = makeStyles((theme) => ({
    error: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.alert.error,
      color: "#ffffff",
    },
    help: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return message ? (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Button
          className={classes.error}
          fullWidth={true}
          onClick={() => {
            history.push(`${consts.PANEL_MAIN}`);
          }}
        >
          {message}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center" className={classes.help}>
          If you continue to have trouble please contact our support team at
          <Hidden xsDown={true}>&nbsp;</Hidden>
          <Hidden smUp={true}>
            <br />
          </Hidden>
          <Link href="mailto:support@cluesnaps.com">support@cluesnaps.com</Link>
        </Typography>
      </Grid>
    </Grid>
  ) : null;
}
