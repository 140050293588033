import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { getResource } from "../../store/actions";
import * as consts from "../../consts";

export default function Prizes(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "100%",
    },
    progress: {
      width: "100%",
    },
    dates: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [prizesMarkup, setPrizesMarkup] = useState("");

  useEffect(() => {
    if (state.game && state.game.id) {
      setBusy(true);
      getResource(
        `${consts.BLOB_URL}${state.game.id}/prizes/markup.html`.toLowerCase(),
        dispatch
      )
        .then((getResourceResult) => {
          setPrizesMarkup(getResourceResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the prizes."
          );
          setBusy(false);
        });
    }
  }, [state.game]);

  const renderPrizes = () => {
    let startDate = new Date(state.game.start);
    let endDate = new Date(state.game.end);
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${state.game.id}/banner.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${state.game.id}/banner.jpg`.toLowerCase()}
              alt=""
              className={classes.image}
            />
          </picture>
        </Grid>
        <Grid item xs={12} className={classes.dates}>
          <Typography variant="caption">
            {`${startDate.toDateString()} - ${endDate.toDateString()}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {prizesMarkup && (
            <div dangerouslySetInnerHTML={{ __html: prizesMarkup }}></div>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy || !state.game || !state.game.id ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        renderPrizes()
      )}
    </Grid>
  );
}
