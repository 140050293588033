import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import * as consts from "../../consts";

export default function FAQ(props) {
  const useStyles = makeStyles((theme) => ({
    section: { marginBottom: theme.spacing(4) },
    title: { fontSize: "1.2rem", marginBottom: theme.spacing(1) },
  }));
  const classes = useStyles();

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.title}>
          General
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">What is Clue Snaps?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              We're a small company based out of Squamish, B.C. check out our{" "}
              <Link
                component={NavLink}
                to={`${consts.PANEL_ABOUT}`}
                className={classes.link}
              >
                <Typography variant="body1">About Us</Typography>
              </Link>{" "}
              page for more details.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What is the point of this game?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              The basic concept is to take a photo for each of the clues on the
              list. Don’t get too hung up on scoring points and completing the
              list though. It’s way more fun to get creative with your team.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I win?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Each game is different. You can score points for approved photos
              and hearts that other teams give your photos. You might also lose
              points for missing clues or for not issuing all of your hearts.
              Many games will have prizes for a bunch of categories. You can see
              the details for each game by opening the specific game and
              checking out the Prizes section.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I run my own game?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              That’s a great question! If you’re interested in running your own
              game for an event or just because you have a great idea, please
              contact us at{" "}
              <Link href="mailto:sales@cluesnaps.com">sales@cluesnaps.com</Link>{" "}
              with any questions. You can also checkout our website at{" "}
              <Link href="https://www.cluesnaps.com" target="_blank">
                cluesnaps.com
              </Link>{" "}
              for more information.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Someone has stolen my work!</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              We take this very seriously. If you believe someone has copied
              your photo or if you are a copyright holder and you believe
              someone is using your work without your permission please contact
              us with details at{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What if I see a photo that violates the rules/law?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              If you believe someone is breaking the law, we take that very
              seriously. Please reach out to our team at{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
              <br />
              I’m not sure if they are breaking the law but their behavior seems
              abusive/rude. Please reach out to our team at{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
              . We have a zero-tolerance policy when it comes to bullying or
              abuse.
              <br />
              If you think someone isn’t playing fair you can find the contact
              information for the event organizer in the Rules section of the
              game.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What are you doing with my photos/data?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              We don’t sell your data, period. We do share your photos and email
              address with the Event organizer and any Sponsors of the game. For
              more specific details please refer to our{" "}
              <Link
                component={NavLink}
                to={`${consts.PANEL_ABOUT}`}
                className={classes.link}
              >
                <Typography variant="body1">Privacy</Typography>
              </Link>{" "}
              and{" "}
              <Link
                component={NavLink}
                to={`${consts.PANEL_TERMS}`}
                className={classes.link}
              >
                <Typography variant="body1">Terms</Typography>
              </Link>{" "}
              pages.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.title}>
          Getting Started
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I join a game?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              To start you’ll need a game code. This will be something that the
              event organizer will have shared with you. You can enter this game
              code on our home page to start the process. Once you’ve entered
              the game code successfully, you’ll be shown details about the game
              and be asked to choose a team name. If you’re trying to join a
              team that is already playing the game don’t proceed. Instead
              checkout our instructions on how to join a team. After selecting a
              valid team name you should be redirected to a welcome screen.
              Congratulations, you’re now competing in a game.
              <br />
              *Some games are marked as private. In this case you must join the
              game using an invitation link you will have received via email.
              This email should contain all the instructions required to join
              the game.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I create a team?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Once you enter a valid game code you’ll be prompted to choose a
              team name.
              <ul>
                <li>Your team name cannot be blank</li>
                <li>Your team name cannot be greater than 12 characters</li>
                <li>
                  Your team name will have to be unique to the game you're
                  joining
                </li>
                <li>
                  If your team name contains any abusive language your team may
                  be removed
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              How do I add people to my team?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Once you’ve successfully created a team you can navigate to the
              Team section to send invitations to others. Each game has a team
              size limit. Once you’ve reached that limit you will be unable to
              send more invitations.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I join a team?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Ask the someone who is already on the team to send you an
              invitation. Once you’ve received the invitation click on the link
              to join the team. You can also go to the home page when you’re
              logged in to see any pending invitations you might have.
              <br />
              *Be sure they are sending the invitation to the email you used
              when you sign up.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I join a team?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Ask the someone who is already on the team to send you an
              invitation. Once you’ve received the invitation click on the link
              to join the team. You can also go to the home page when you’re
              logged in to see any pending invitations you might have.
              <br />
              *Be sure they are sending the invitation to the email you used
              when you sign up.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">When does the game end?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Each game has its own start and end date. You can see this
              information before you join as well as under the Rule section for
              the game.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.title}>
          Photos
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Why is my photo pending?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              When a photo is uploaded it must be approved by a game
              administrator before it becomes available to the public. They are
              checking to make sure your photo doesn’t violate any of the game’s
              rules. This can take some time especially if you’ve uploaded your
              photo outside of regular business hours or if the game has a lot
              of teams. Please be patient. If you want more details you can
              check the Rules section of the game you’re playing. If you think
              something is broken you can also send a request to our support
              team at{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              Why can't I comment on a photo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Some games have comments disabled. You can check this by
              navigating to the Rules section for the game.
              <br />
              *You must verify your email address in order to comment. When you
              first signed up you should have received an email asking you to
              verify your address. If you did not receive this email you can go
              to the Account section to resend the verification email or you can
              contact our support team by sending an email from the address you
              signed up with to{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How do I upload a photo?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              To upload a photo navigate to the Clues section for the game.
              Click on the clue you wish to upload a photo for and click UPLOAD
              PHOTO. From here you should be able to take a photo or browse your
              phone’s gallery to select an image. Once you’ve selected an image
              you should see a SUBMIT button appear below. Click this button to
              submit the photo for approval.
              <br />
              *You must verify your email address in order to upload photos.
              When you first signed up you should have received an email asking
              you to verify your address. If you did not receive this email you
              can go to the Account section to resend the verification email or
              you can contact our support team by sending an email from the
              address you signed up with to{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What if I want to change a photo I’ve already submitted?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Not a problem. You can swap photos out as long as the game hasn’t
              ended. To do this navigate to the Clues section and click on the
              clue you wish to update. From here you can click on UPLOAD PHOTO
              to take a different photo or select one from your phone’s gallery.
              Once you’ve selected an image you will see the SUBMIT button
              below. Click this button to replace the photo.
              <br />
              *When you replace a photo all of the hearts you received for that
              photo will be returned
              <br />
              *When you replace a photo all of the comments on that photo will
              be lost
              <br />
              *When you replace a photo it will have to go through the approval
              process again
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              Can I modify a photo with software?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              As long as the game’s rules don’t prohibit this then absolutely.
              In fact, we encourage that kind of creativity. Adding filters or
              text to your photo can be a great way to stand out and capture
              more of those valuable hearts.
              <br />
              *be sure to not expose yourself to copyright infringement,
              original works only please
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What kind of content can I upload?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              For details please take a look at our{" "}
              <Link
                component={NavLink}
                to={`${consts.PANEL_TERMS}`}
                className={classes.link}
              >
                <Typography variant="body1">Terms</Typography>
              </Link>{" "}
              page. The basic rule is don’t upload a photo that you wouldn’t
              want your child, grandmother, or your boss to see. If you’re not
              sure if it is crossing a line then maybe it is best to come up
              with a different idea. We want you to have fun but we don’t want
              your fun to take someone else’s fun away.
              <br />
              Of course, it shouldn’t have to be said but we’re going to say it
              just to be clear. If you upload something illegal, we’re going to
              inform the authorities. That includes photos of you or your
              teammates breaking the law, like trespassing, vandalism, or
              speeding. This also includes uploading photos of people without
              their consent.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.title}>
          Clues
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              I don’t know what this clue means?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Don’t worry, there are no “wrong” answers. Just have fun with it.
              Some suggestions when you just don’t know what to do for a clue:
              <ul>
                <li>
                  Borrow, Look at what other teams are uploading for some
                  inspiration
                </li>
                <li>
                  Go abstract, take an artsy overly zoomed in photo of whatever
                  and let the audience figure it out
                </li>
                <li>
                  Cats &amp; Dogs, when in doubt just grab a photo of a cute
                  kitten or puppy
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">How many clues are there?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Each game is different. You might be dealing with as little as 1
              clue or as many as 100. Some games may even add clues as the game
              progresses. You can always checkout the Rules section of the game
              for more details.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.title}>
          Votes
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              What is the deal with these hearts?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              oUnlike most of the social platforms you’re probably familiar
              with, here at Clue Snaps hearts are a valuable commodity. Each
              player is issued a limited number of hearts when they join the
              game. You can use these hearts to give your fellow competitors
              kudos on their creative works of art. Each game is different but
              for the most part you score points when one of your photos
              receives a heart.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">
              Why can't I vote for a photo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              Each game has a limited number of votes per user. You can see how
              many votes you have left by navigating to the Votes section. In
              the upper right corner of this page you will see a row of hearts.
              The solid hearts indicate votes you have already issued. If you do
              not have any empty hearts left you will not be able to vote on any
              additional photos.
              <br />
              *You must verify your email address in order to vote. When you
              first signed up you should have received an email asking you to
              verify your address. If you did not receive this email you can go
              to the Account section to resend the verification email or you can
              contact our support team by sending an email from the address you
              signed up with to{" "}
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
