import React from "react"; // eslint-disable-line no-unused-vars
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Hidden, Link } from "@material-ui/core";
import Login from "../navigation/login";
import Games from "../navigation/games";
import Invitations from "../navigation/invitations";
import JoinNew from "../navigation/joinNew";
import JoinGlobal from "../navigation/joinGlobal";

import * as consts from "../../consts";

export default function Main() {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.5rem",
    },
    message: {
      marginBottom: theme.spacing(2),
    },
    logo: {
      textAlign: "center",
      margin: theme.spacing(2),
    },
    help: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  return (
    <Grid item container xs={12}>
      {isAuthenticated ? (
        <Grid item container xs={12}>
          <Grid item xs={12} sm={6}>
            <Games />
            <Invitations />
          </Grid>
          <Grid item xs={12} sm={6}>
            <JoinNew />
            <JoinGlobal />
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" className={classes.header}>
              Welcome to Clue Snaps!
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.logo}>
            <img
              alt="Clue Snaps Logo"
              src={`${consts.SHARED_URL}logo.png`.toLowerCase()}
              width="152"
              height="152"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              className={classes.message}
            >
              If you're new you can sign up for FREE by clicking the sign up
              button below otherwise use the sign in button to access your
              games.
            </Typography>
          </Grid>
          <Login />
          <Grid item xs={12}>
            <Typography variant="body1" align="center" className={classes.help}>
              If you're having any trouble please contact our support team at
              <Hidden xsDown={true}>&nbsp;</Hidden>
              <Hidden smUp={true}>
                <br />
              </Hidden>
              <Link href="mailto:support@cluesnaps.com">
                support@cluesnaps.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
