import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { getLeaderboard } from "../../store/actions";
import { State } from "../../store/state";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function Score(props) {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.5rem",
    },
    section: {
      marginBottom: theme.spacing(2),
    },
    thumbnail: {
      width: "100%",
    },
    tile: {
      margin: theme.spacing(1),
      height: "20vh",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    link: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    score: {
      color: theme.palette.icons.score,
    },
    progress: {
      width: "100%",
    },
    yourTeam: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [leaderboard, setLeaderboard] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getLeaderboard(state.game.id, getAccessTokenSilently, dispatch)
        .then((getLeaderboardResult) => {
          getLeaderboardResult.scores.sort((a, b) =>
            a.totalScore > b.totalScore
              ? -1
              : b.totalScore > a.totalScore
              ? 1
              : 0
          );
          setLeaderboard(getLeaderboardResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the leaderboard."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const renderTeamDetails = () => {
    let score =
      leaderboard && leaderboard.scores
        ? leaderboard.scores.find((score) => {
            return score.teamID == state.game.team.id;
          })
        : null;
    if (score) {
      return (
        <Grid item xs={12} className={classes.yourTeam}>
          <Typography variant="body1">Your team's score breakdown:</Typography>
          <Typography variant="body1">
            Clue Points: {score.clueScore}
          </Typography>
          <Typography variant="body1">
            Vote Points: {score.voteScore}
          </Typography>
          <Typography variant="body1">
            Clue Penalty: {score.cluePenalty}
          </Typography>
          <Typography variant="body1">
            Vote Penalty: {score.votePenalty}
          </Typography>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderLeaderboard = () => {
    return (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">Team</TableCell>
                <TableCell component="th">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.scores &&
                leaderboard.scores.map((score, index) => {
                  if (index < 10 || score.teamID == state.game.team.id) {
                    return (
                      <TableRow key={score.teamID}>
                        <TableCell component="th" scope="row">
                          {score.title}
                        </TableCell>
                        <TableCell>{score.totalScore}</TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {error ? (
        renderError()
      ) : busy || !state.game || !state.game.id ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        renderLeaderboard()
      )}
      {renderTeamDetails()}
    </Grid>
  );
}
