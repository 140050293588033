import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import * as consts from "../../consts";
import ResourceViewer from "../presentational/resourceViewer";
import PanelHeader from "../presentational/panelHeader";
import LegalLinks from "../navigation/legalLinks";

export default function Terms() {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <Grid item container xs={12} className={classes.section}>
      <PanelHeader title="Terms &amp; Conditions" />
      <ResourceViewer resourceUrl={consts.RESOURCE_URL_TERMS} />
      <LegalLinks />
    </Grid>
  );
}
