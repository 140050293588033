import React, { useReducer } from "react"; // eslint-disable-line no-unused-vars
import * as consts from "../consts";
import { State } from "./state";

const darkMode = localStorage.getItem(consts.STORAGE_DARK_MODE)
  ? JSON.parse(localStorage.getItem(consts.STORAGE_DARK_MODE))
  : window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ? true
  : false;

const initialState = {
  busy: true,
  alerts: [],
  game: {},
  darkMode: darkMode,
  settings: {
    appNotifications: false,
    emailNotifications: false,
    emailAddress: "",
    emailVerified: false,
  },
  notifications: [],
};

function reducer(state, action) {
  switch (action.type) {
    case consts.ACTION_SET_BUSY:
      return { ...state, busy: action.payload };
    case consts.ACTION_ADD_ALERT:
      return { ...state, alerts: [...state.alerts, action.payload] };
    case consts.ACTION_DISMISS_ALERT: {
      let updatedAlerts = state.alerts.slice();
      updatedAlerts.shift();
      return {
        ...state,
        alerts: updatedAlerts,
      };
    }
    case consts.ACTION_SET_DARK_MODE:
      localStorage.setItem(consts.STORAGE_DARK_MODE, action.payload);
      return { ...state, darkMode: action.payload };
    case consts.ACTION_SET_SETTINGS:
      return { ...state, settings: action.payload };
    case consts.ACTION_SET_GAME:
      return { ...state, game: action.payload };
    case consts.ACTION_SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case consts.ACTION_REMOVE_NOTIFICATION: {
      let updatedNotifications = state.notifications.slice();
      let notificationIndex = 0;
      while (notificationIndex < updatedNotifications.length) {
        if (updatedNotifications[notificationIndex].id == action.payload.id) {
          updatedNotifications.splice(notificationIndex, 1);
        } else {
          ++notificationIndex;
        }
      }
      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
  }
}

export function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <State.Provider value={value}>{props.children}</State.Provider>;
}
