import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
} from "@material-ui/core";
import { getInvitations } from "../../store/actions";
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../presentational/errorMessage";
import Progress from "../presentational/progress";
import PanelHeader from "../presentational/panelHeader";
import { NavLink } from "react-router-dom";
import * as consts from "../../consts";
import LazyLoad from "react-lazyload";

export default function Invitations() {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    list: {
      width: "100%",
    },
    listItem: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const { dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setBusy(true);
      getInvitations(getAccessTokenSilently, dispatch)
        .then((getInvitationsResult) => {
          setInvitations(getInvitationsResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            `Error: There was an issue getting your invitations. ${
              exception.response && exception.response.data
            }`
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading]);

  const renderInvitation = (invitation) => {
    return (
      <LazyLoad once key={invitation.id}>
        <Link
          component={NavLink}
          to={`${consts.PANEL_JOIN}/${invitation.game.id}/${
            invitation.team ? invitation.team.id : "-"
          }/${invitation ? invitation.id : "-"}`}
          color="inherit"
        >
          <ListItem
            disableGutters={true}
            classes={{ container: classes.listItem }}
          >
            <ListItemAvatar>
              <Avatar
                alt={`Thumbnail for ${invitation.game.title}`}
                src={`${consts.BLOB_URL}${invitation.game.id}/banner_thumbnail.jpg`.toLowerCase()}
                imgProps={{ width: "40", height: "40" }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="body1">{invitation.game.title}</Typography>
              }
              secondary={
                <Typography variant="caption">
                  {invitation.team && invitation.team.title}
                </Typography>
              }
            />
          </ListItem>
        </Link>
        <Divider variant="middle" component="li" />
      </LazyLoad>
    );
  };

  const renderInvitations = () => {
    if (invitations.length > 0) {
      return (
        <Grid item xs={12}>
          <List className={classes.list}>
            {invitations.map((invitation) => {
              return renderInvitation(invitation);
            })}
          </List>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="body1">
            You do not have any outstanding invitations
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      <PanelHeader title="Invitations" />
      <ErrorMessage message={error} />
      <Progress busy={busy} />
      {!error && !busy && renderInvitations()}
    </Grid>
  );
}
