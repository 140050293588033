import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardHeader,
  IconButton,
  CardActions,
  Collapse,
  CardContent,
  Badge,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import {
  Favorite as HeartIcon,
  Chat as CommentsIcon,
} from "@material-ui/icons";
import { getPost, getComments, addComment } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";

export default function Post(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    postWrapper: {
      margin: "auto",
    },
    commentsWrapper: {
      margin: "auto",
    },
    messagesWrapper: {
      margin: "auto",
    },
    post: {
      [theme.breakpoints.down("xs")]: {
        padding: "8px 0 !important",
      },
    },
    action: {
      marginTop: "0px",
      marginRight: "0px",
    },
    submit: {},
    image: {
      width: "100%",
    },
    signature: {
      textAlign: "right",
    },
    helperText: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [post, setPost] = useState({});
  const [busy, setBusy] = useState(true);
  const { clueID, teamID } = useParams();
  const [message, setMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsBusy, setCommentsBusy] = useState(true);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getPost(
        state.game.id,
        teamID,
        clueID,
        getAccessTokenSilently,
        dispatch
      ).then((getPostResult) => {
        setPost(getPostResult);
        setBusy(false);
      });
      if (state.game.commentsEnabled) {
        setCommentsBusy(true);
        getComments(
          state.game.id,
          teamID,
          clueID,
          getAccessTokenSilently,
          dispatch
        )
          .then((getCommentsResult) => {
            setMessage("");
            setComments(getCommentsResult);
            setCommentsBusy(false);
          })
          .catch((exception) => {
            setError(
              exception.response && exception.response.data
                ? exception.response.data
                : "Error: There was an issue with this request."
            );
            setCommentsBusy(false);
          });
      }
    }
  }, [isAuthenticated, isLoading, state.game, clueID, teamID]);

  const getDisplayDate = (date) => {
    let displayDate = `posted on ${date.toDateString()}`;
    let seconds = Math.floor((new Date() - date) / 1000);
    let count = 0;
    let interval = seconds;
    if (interval < 5) {
      displayDate = `just posted`;
    } else {
      if (interval >= 1 && interval < 60) {
        count = Math.floor(interval);
        displayDate = `posted ${count} second${count > 1 ? "s" : ""} ago`;
      } else {
        interval = interval / 60;
        if (interval >= 1 && interval < 60) {
          count = Math.floor(interval);
          displayDate = `posted ${count} minute${count > 1 ? "s" : ""} ago`;
        } else {
          interval = interval / 60;
          if (interval >= 1 && interval < 24) {
            count = Math.floor(interval);
            displayDate = `posted ${count} hour${count > 1 ? "s" : ""} ago`;
          }
        }
      }
    }
    return displayDate;
  };
  const renderPost = () => {
    return (
      <Grid item xs={12} className={classes.post}>
        <Card raised={true} id={`${clueID}-${teamID}`} className={classes.card}>
          <CardHeader
            classes={{ action: classes.action }}
            action={
              post.voteCount > 0 && (
                <Badge
                  badgeContent={post.voteCount}
                  color="primary"
                  classes={{ badge: classes.badge }}
                >
                  <HeartIcon className={classes.heart} />
                </Badge>
              )
            }
            title={post.clueTitle}
            subheader={post.teamTitle}
          />
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${clueID}-${teamID}.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${clueID}-${teamID}.jpg`.toLowerCase()}
              alt={`${post.teamTitle} - ${post.clueTitle}`}
              className={classes.image}
            />
          </picture>
          {state.game.commentsEnabled && (
            <CardContent>
              {commentsBusy && (
                <LinearProgress
                  color="secondary"
                  className={classes.progress}
                />
              )}
              <Grid
                item
                container
                spacing={2}
                xs={12}
                className={classes.commentsWrapper}
              >
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className={classes.messagesWrapper}
                >
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {comments.map((comment) => {
                    return (
                      <React.Fragment key={comment.created}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            {comment.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.signature}>
                          <Typography variant="caption">
                            {`-${comment.author} ${getDisplayDate(
                              new Date(comment.created)
                            )}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="commentInput"
                    value={message}
                    helperText={`${message.length}/500`}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    disabled={commentsBusy}
                    multiline={true}
                    rows={4}
                    fullWidth={true}
                    label="Message"
                    variant="outlined"
                    onChange={(event) => {
                      if (event.target.value.length <= 500) {
                        setMessage(event.target.value);
                      }
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      if (state.settings.emailVerified) {
                        setCommentsBusy(true);
                        addComment(
                          state.game.id,
                          teamID,
                          clueID,
                          message,
                          getAccessTokenSilently,
                          dispatch
                        ).then((comment) => {
                          let updatedComments = comments.slice();
                          updatedComments.push(comment);
                          setComments(updatedComments);
                          setMessage("");
                          setCommentsBusy(false);
                        });
                      }
                    }}
                    variant="outlined"
                    color="primary"
                    disabled={commentsBusy || message.length > 500}
                    fullWidth={true}
                    className={classes.submit}
                  >
                    <Typography variant="h6">Submit</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderPost()}
    </Grid>
  );
}
