import * as consts from "../consts";
import axios from "axios";

function handleError(exception, message, dispatch) {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-Play:", exception);
  }
  if (message && dispatch) {
    addAlert(
      {
        message: message,
        type: consts.ALERT_TYPE_ERROR,
      },
      dispatch
    );
  }
}

function handleSuccess(response, message, dispatch) {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-Play:", response);
  }
  if (message && dispatch) {
    addAlert(
      {
        message: message,
        type: consts.ALERT_TYPE_SUCCESS,
      },
      dispatch
    );
  }
}

export function setBusy(busy, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_BUSY,
      payload: busy,
    });
    resolve();
  });
}

export function setDarkMode(darkMode, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_DARK_MODE,
      payload: darkMode,
    });
    resolve();
  });
}

export function addAlert(alert, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_ADD_ALERT,
      payload: alert,
    });
    resolve();
  });
}

export function dismissAlert(dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_DISMISS_ALERT,
    });
    resolve();
  });
}

export function getResource(path, dispatch) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${path}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((exception) => {
        handleError(exception, `Unable to retrieve resource ${path}`, dispatch);
        reject(exception);
      });
  });
}

export function getHTTPHeaders(getAccessTokenSilently) {
  return new Promise((resolve) => {
    if (getAccessTokenSilently) {
      getAccessTokenSilently()
        .then((token) => {
          let options = token
            ? { headers: { Authorization: `Bearer ${token}` } }
            : {};
          resolve(options);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      resolve({});
    }
  });
}

export function getSettings(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/settings`, options)
        .then((response) => {
          let settings = response.data;
          dispatch({
            type: consts.ACTION_SET_SETTINGS,
            payload: settings,
          });
          resolve(settings);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve your settings`, dispatch);
          reject(exception);
        });
    });
  });
}

export function saveAppNotificationsSettings(
  appNotifications,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    registerSubscription(appNotifications).then((subscription) => {
      let data = {
        appNotifications: appNotifications,
        subscription: JSON.stringify(subscription),
      };
      getHTTPHeaders(getAccessTokenSilently).then((options) => {
        axios
          .put(`${consts.SERVICE_URL}/settings/appNotifications`, data, options)
          .then((response) => {
            let settings = response.data;
            dispatch({
              type: consts.ACTION_SET_SETTINGS,
              payload: settings,
            });
            handleSuccess(response, `Successfully saved settings`, dispatch);
            resolve(settings);
          })
          .catch((exception) => {
            handleError(exception, `Unable to save settings`, dispatch);
            reject(exception);
          });
      });
    });
  });
}

export function saveEmailNotificationsSettings(
  emailNotifications,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = {
      emailNotifications: emailNotifications,
    };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .put(`${consts.SERVICE_URL}/settings/emailNotifications`, data, options)
        .then((response) => {
          let settings = response.data;
          dispatch({
            type: consts.ACTION_SET_SETTINGS,
            payload: settings,
          });
          handleSuccess(response, `Successfully saved settings`, dispatch);
          resolve(settings);
        })
        .catch((exception) => {
          handleError(exception, `Unable to save settings`, dispatch);
          reject(exception);
        });
    });
  });
}

export function resendEmailVerification(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {};
      axios
        .post(`${consts.SERVICE_URL}/settings/email/revalidate`, data, options)
        .then((response) => {
          handleSuccess(
            response,
            `Successfully resent email verification`,
            dispatch
          );
          resolve();
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to resend email verification`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function resetPassword(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {};
      axios
        .post(`${consts.SERVICE_URL}/settings/password/reset`, data, options)
        .then((response) => {
          handleSuccess(response, `Successfully sent password reset`, dispatch);
          resolve();
        })
        .catch((exception) => {
          handleError(exception, `Unable to send password reset`, dispatch);
          reject(exception);
        });
    });
  });
}

export function updateEmailAddress(email, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {
        email: email,
      };
      axios
        .post(`${consts.SERVICE_URL}/settings/email/`, data, options)
        .then((response) => {
          let settings = response.data;
          dispatch({
            type: consts.ACTION_SET_SETTINGS,
            payload: settings,
          });
          handleSuccess(response, `Successfully changed email`, dispatch);
          resolve();
        })
        .catch((exception) => {
          handleError(exception, `Unable to change email`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getGames(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games`, options)
        .then((response) => {
          let games = response.data;
          resolve(games);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve your games`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getGame(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}`, options)
        .then((response) => {
          let game = response.data;
          dispatch({
            type: consts.ACTION_SET_GAME,
            payload: game,
          });
          resolve(game);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve game ${gameID}`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getInvitations(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/invitations`, options)
        .then((response) => {
          let invitations = response.data;
          resolve(invitations);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve your invitations`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function getInvitation(
  gameID,
  teamID,
  invitationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID ? gameID : "-"}/teams/${
            teamID ? teamID : "-"
          }/invitations/${invitationID ? invitationID : "-"}`,
          options
        )
        .then((response) => {
          let invitation = response.data;
          resolve(invitation);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve invitation for ${gameID}`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function declineInvitation(
  gameID,
  invitationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/invitations/${
            invitationID ? invitationID : "-"
          }/games/${gameID ? gameID : "-"}`,
          options
        )
        .then((response) => {
          let invitation = response.data;
          resolve(invitation);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to decline invitation for ${gameID}`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function getClues(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/clues`, options)
        .then((response) => {
          let clues = response.data;
          resolve(clues);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve game clues for ${gameID}`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function getClue(gameID, clueID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/clues/${clueID}`, options)
        .then((response) => {
          let clue = response.data;
          resolve(clue);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve clue ${clueID}`, dispatch);
          reject(exception);
        });
    });
  });
}

export function createTeam(gameID, teamName, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {
        teamName: teamName,
      };
      axios
        .post(`${consts.SERVICE_URL}/games/${gameID}/teams`, data, options)
        .then((response) => {
          let game = response.data;
          dispatch({
            type: consts.ACTION_SET_GAME,
            payload: game,
          });
          handleSuccess(
            response,
            `Successfully created team ${teamName}`,
            dispatch
          );
          resolve(game);
        })
        .catch((exception) => {
          handleError(exception, `Unable to create team ${teamName}`, dispatch);
          reject(exception);
        });
    });
  });
}

export function joinTeam(
  gameID,
  teamID,
  invitationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = {};
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .put(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/invitations/${invitationID}`,
          data,
          options
        )
        .then((response) => {
          let game = response.data;
          dispatch({
            type: consts.ACTION_SET_GAME,
            payload: game,
          });
          handleSuccess(
            response,
            `Successfully joined team ${teamID}`,
            dispatch
          );
          resolve(game);
        })
        .catch((exception) => {
          handleError(exception, `Unable to join team ${teamID}`, dispatch);
          reject(exception);
        });
    });
  });
}

export function saveClue(
  gameID,
  clueID,
  blob,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = new FormData();
    data.append("files", blob);
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .put(`${consts.SERVICE_URL}/games/${gameID}/clues/${clueID}`, data, {
          ...options,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        })
        .then((response) => {
          let clue = response.data;
          handleSuccess(response, `Clue Submitted`, dispatch);
          resolve(clue);
        })
        .catch((exception) => {
          handleError(exception, `Unable to save clue`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getVotes(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/votes`, options)
        .then((response) => {
          let games = response.data;
          resolve(games);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve your games`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getCandidates(
  gameID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/clues/${clueID}/candidates`,
          options
        )
        .then((response) => {
          let candidates = response.data;
          resolve(candidates);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve candidates`, dispatch);
          reject(exception);
        });
    });
  });
}

export function addVote(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = {};
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/votes`,
          data,
          options
        )
        .then((response) => {
          let vote = response.data;
          handleSuccess(response, `Successfully voted`, dispatch);
          resolve(vote);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to process vote ${teamID}-${clueID}`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function removeVote(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/votes`,
          options
        )
        .then((response) => {
          let vote = response.data;
          handleSuccess(response, `Successfully voted`, dispatch);
          resolve(vote);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to process vote ${teamID}-${clueID}`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function getTeamMembership(
  gameID,
  teamID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/members`,
          options
        )
        .then((response) => {
          let teamMembers = response.data;
          resolve(teamMembers);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve team members`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getPlayers(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/players`, options)
        .then((response) => {
          let players = response.data;
          resolve(players);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve players`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getTeamInvitations(
  gameID,
  teamID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/invitations`,
          options
        )
        .then((response) => {
          let invitations = response.data;
          resolve(invitations);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve team invitations`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function addTeamInvitation(
  gameID,
  teamID,
  email,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = { email: email };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/invitations`,
          data,
          options
        )
        .then((response) => {
          let teamInvitation = response.data;
          handleSuccess(response, `Successfully added invitation`, dispatch);
          resolve(teamInvitation);
        })
        .catch((exception) => {
          handleError(exception, `Unable to add invitation`, dispatch);
          reject(exception);
        });
    });
  });
}

export function removeTeamInvitation(
  gameID,
  teamID,
  invitationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/invitations/${invitationID}`,
          options
        )
        .then((response) => {
          let teamInvitation = response.data;
          handleSuccess(response, `Successfully removed invitation`, dispatch);
          resolve(teamInvitation);
        })
        .catch((exception) => {
          handleError(exception, `Unable to remove invitation`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getFeed(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.BLOB_URL}${gameID}/feed.json`.toLowerCase(), options)
      .then((response) => {
        let feed = response.data;
        resolve(feed);
      })
      .catch((exception) => {
        handleError(exception, `Unable to retrieve game activities`, dispatch);
        reject(exception);
      });
  });
}

export function getComments(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/comments`,
          options
        )
        .then((response) => {
          let comments = response.data;
          resolve(comments);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve clue comments`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getMentions(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/mentions`,
          options
        )
        .then((response) => {
          let mentions = response.data;
          resolve(mentions);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve clue mentions`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getPost(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/post`,
          options
        )
        .then((response) => {
          let post = response.data;
          resolve(post);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve clue post`, dispatch);
          reject(exception);
        });
    });
  });
}

export function addComment(
  gameID,
  teamID,
  clueID,
  message,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = { message: message };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/comments`,
          data,
          options
        )
        .then((response) => {
          let comment = response.data;
          resolve(comment);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve clue comments`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getLeaderboard(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/leaderboard`, options)
        .then((response) => {
          let leaderboard = response.data;
          resolve(leaderboard);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve leaderboard`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getNotifications(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/notifications`, options)
        .then((response) => {
          let notifications = response.data.sort((a, b) => {
            return a.posted > b.posted ? -1 : 1;
          });
          dispatch({
            type: consts.ACTION_SET_NOTIFICATIONS,
            payload: notifications,
          });
          resolve(notifications);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve your notifications`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function removeNotification(
  notificationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/notifications/${notificationID}`,
          options
        )
        .then((response) => {
          let notification = response.data;
          dispatch({
            type: consts.ACTION_REMOVE_NOTIFICATION,
            payload: notification,
          });
          resolve(notification);
        })
        .catch((exception) => {
          handleError(exception, `Failed to remove notification`, dispatch);
          reject(exception);
        });
    });
  });
}

export function canUseWebP() {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      let result = image.width > 0 && image.height > 0;
      resolve(result);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src =
      "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
  });
}

export function registerSubscription(appNotifications) {
  return new Promise((resolve, reject) => {
    if (
      appNotifications &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      navigator.serviceWorker.ready
        .then((serviceWorker) => {
          serviceWorker.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(
                consts.VAPID_PUBLIC_KEY
              ),
            })
            .then((subscription) => {
              resolve(subscription);
            })
            .catch((exception) => {
              handleError(exception, null, null);
              resolve({});
            });
        })
        .catch((exception) => {
          handleError(exception, null, null);
          resolve({});
        });
    } else {
      resolve({});
    }
  });
}
export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
