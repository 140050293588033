import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../store/state";
import { dismissAlert, getNotifications, getSettings } from "../store/actions";
import Header from "./navigation/header";
import Alert from "./presentational/alert";
import Breadcrumb from "./navigation/breadcrumb";
import Main from "./panels/main";
import Settings from "./panels/settings";
import About from "./panels/about";
import Privacy from "./panels/privacy";
import Terms from "./panels/terms";
import Cookies from "./panels/cookies";
import FAQ from "./panels/faq";
import Join from "./panels/join";
import Game from "./panels/game";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../consts";
import { Paper, Grid, Button } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Progress from "./presentational/progress";

const App = () => {
  const { state, dispatch } = useContext(State);
  const theme = createMuiTheme(
    state.darkMode ? consts.THEME_DARK : consts.THEME_LIGHT
  );
  const useStyles = makeStyles((theme) => ({
    main: {
      backgroundColor: state.darkMode ? "#000000" : "#ffffff",
      minHeight: "100vh",
      "& h2": {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: "80px",
      padding: theme.spacing(2),
      minHeight: "100vh",
      [theme.breakpoints.down("xs")]: {
        marginTop: "70px",
      },
    },
    emailError: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: "#b2102f",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#ea4f58",
      },
    },
  }));
  const classes = useStyles();

  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user,
  } = useAuth0();

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getSettings(getAccessTokenSilently, dispatch).then(() => {
        getNotifications(getAccessTokenSilently, dispatch);
      });
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        {state.alerts.length > 0 && (
          <Alert
            alert={state.alerts[0]}
            onDismiss={() => {
              dismissAlert(dispatch);
            }}
          />
        )}
        <Header />
        <Paper className={classes.paper}>
          {isLoading ? (
            <Grid item container xs={12}>
              <Progress busy={true} />
            </Grid>
          ) : (
            <React.Fragment>
              {user && !user.email_verified && (
                <Button
                  className={classes.emailError}
                  fullWidth={true}
                  onClick={() => {
                    history.push(`${consts.PANEL_SETTINGS}`);
                  }}
                >
                  You need to validated your email
                </Button>
              )}
              <Breadcrumb />
              <Switch>
                <Route
                  path={`${consts.PANEL_GAME}/:gameID`}
                  render={() => <Game />}
                />
                <Route
                  path={`${consts.PANEL_JOIN}/:gameID?/:teamID?/:invitationID?`}
                  render={() => <Join />}
                />
                <Route
                  path={`${consts.PANEL_SETTINGS}`}
                  render={() => <Settings />}
                />
                <Route
                  path={`${consts.PANEL_ABOUT}`}
                  render={() => <About />}
                />
                <Route
                  path={`${consts.PANEL_PRIVACY}`}
                  render={() => <Privacy />}
                />
                <Route
                  path={`${consts.PANEL_COOKIES}`}
                  render={() => <Cookies />}
                />
                <Route
                  path={`${consts.PANEL_TERMS}`}
                  render={() => <Terms />}
                />
                <Route path={`${consts.PANEL_FAQ}`} render={() => <FAQ />} />
                <Route render={() => <Main />} />
              </Switch>
            </React.Fragment>
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};
export default App;
