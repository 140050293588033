import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

export default function Progress({ busy }) {
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
      width: "100vw",
    },
  }));
  const classes = useStyles();

  return busy ? (
    <LinearProgress color="secondary" className={classes.progress} />
  ) : null;
}
