import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as consts from "../../consts";

export default function Alert(props) {
  const useStyles = makeStyles((theme) => ({
    error: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: theme.palette.alert.error,
      },
    },
    success: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: theme.palette.alert.success,
      },
    },
  }));
  const classes = useStyles();
  if (props.alert) {
    return (
      <Snackbar
        className={
          props.alert.type == consts.ALERT_TYPE_ERROR
            ? classes.error
            : classes.success
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={props.alert ? true : false}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          } else {
            props.onDismiss();
          }
        }}
        message={props.alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                props.onDismiss();
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  } else {
    return null;
  }
}
