import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";

export default function PanelHeader({ title }) {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.5rem",
    },
  }));
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant="h2" className={classes.header}>
        {title}
      </Typography>
    </Grid>
  );
}
