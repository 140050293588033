import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
} from "@material-ui/core";
import { getGames } from "../../store/actions";
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessage from "../presentational/errorMessage";
import Progress from "../presentational/progress";
import PanelHeader from "../presentational/panelHeader";
import { NavLink } from "react-router-dom";
import * as consts from "../../consts";
import LazyLoad from "react-lazyload";

export default function Games() {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    list: {
      width: "100%",
    },
    listItem: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const { dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [games, setGames] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setBusy(true);
      getGames(getAccessTokenSilently, dispatch)
        .then((getGamesResult) => {
          setGames(getGamesResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            `Error: There was an issue getting your games. ${
              exception.response && exception.response.data
            }`
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading]);

  const renderGame = (game) => {
    return (
      <LazyLoad once key={game.id}>
        <Link
          component={NavLink}
          to={`${consts.PANEL_GAME}/${game.id}`}
          color="inherit"
        >
          <ListItem
            disableGutters={true}
            classes={{ container: classes.listItem }}
          >
            <ListItemAvatar>
              <Avatar
                alt={`Thumbnail for ${game.title}`}
                src={`${consts.BLOB_URL}${game.id}/banner_thumbnail.jpg`.toLowerCase()}
                imgProps={{ width: "40", height: "40" }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant="body1">{game.title}</Typography>}
              secondary={
                <Typography variant="caption">
                  {game.team && game.team.title}
                </Typography>
              }
            />
          </ListItem>
        </Link>
        <Divider variant="middle" component="li" />
      </LazyLoad>
    );
  };

  const renderGames = () => {
    if (games.length > 0) {
      return (
        <Grid item xs={12}>
          <List className={classes.list}>
            {games.map((game) => {
              return renderGame(game);
            })}
          </List>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="body1">You have not joined any games</Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      <PanelHeader title="Games" />
      <ErrorMessage message={error} />
      <Progress busy={busy} />
      {!error && !busy && renderGames()}
    </Grid>
  );
}
