import React, { useEffect, useContext, useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { getResource } from "../../store/actions";
import ErrorMessage from "./errorMessage";
import Progress from "./progress";
import { State } from "../../store/state";

export default function ResourceViewer({ resourceUrl }) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { dispatch } = useContext(State);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [markup, setMarkup] = useState("");

  useEffect(() => {
    setBusy(true);
    getResource(resourceUrl, dispatch)
      .then((getResourceResult) => {
        setMarkup(getResourceResult);
        setBusy(false);
      })
      .catch(() => {
        setError(
          `Error: There was an issue requesting this resource (${resourceUrl}). Please refresh the page to try again.`
        );
        setBusy(false);
      });
  }, []);

  return (
    <Grid item container xs={12} className={classes.section}>
      <ErrorMessage message={error} />
      <Progress busy={busy} />
      {markup && (
        <Grid item xs={12} className={classes.section}>
          <div dangerouslySetInnerHTML={{ __html: markup }}></div>
        </Grid>
      )}
    </Grid>
  );
}
