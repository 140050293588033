import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "./components/auth/authProvider";
import { Provider } from "./store/provider";
import App from "./components/app";

if (process.env.NODE_ENV === "development") {
  console.log("CSV2-Play: Loading");
}

let registerServiceWorker = function (config) {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  if (config && config.onSuccess) {
                    config.onSuccess(registration);
                  }
                }
              }
            };
          };
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(
              "CSV2-Play: Error during service worker registration",
              error
            );
          }
        });
    });
  }
};

//Not sure if we need this
let unregisterServiceWorker = function () {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
};

let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-Play: Initializing React component");
  }
  let app = document.getElementById("app");
  if (app) {
    ReactDOM.render(
      <React.Fragment>
        <ScopedCssBaseline>
          <Router>
            <AuthProvider>
              <Provider>
                <App />
              </Provider>
            </AuthProvider>
          </Router>
        </ScopedCssBaseline>
      </React.Fragment>,
      app
    );
    if (process.env.NODE_ENV === "production") {
      registerServiceWorker();
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.log("CSV2-Play: No element to insert component into");
    }
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("CSV2-Play: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}
