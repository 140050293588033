import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import * as consts from "../../consts";

export default function LegalLinks() {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    link: {
      display: "inline-block",
      "& p": { fontSize: ".75rem" },
    },
  }));
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.section}
      direction="row-reverse"
    >
      <Typography variant="caption">
        <Link
          component={NavLink}
          to={`${consts.PANEL_PRIVACY}`}
          className={classes.link}
        >
          <Typography variant="body1">Privacy Policy</Typography>
        </Link>
        &nbsp;|&nbsp;
        <Link
          component={NavLink}
          to={`${consts.PANEL_TERMS}`}
          className={classes.link}
        >
          <Typography variant="body1">Terms of Service</Typography>
        </Link>
        &nbsp;|&nbsp;
        <Link
          component={NavLink}
          to={`${consts.PANEL_COOKIES}`}
          className={classes.link}
        >
          <Typography variant="body1">Cookie Policy</Typography>
        </Link>
      </Typography>
    </Grid>
  );
}
