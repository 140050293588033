import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory, NavLink } from "react-router-dom";
import * as consts from "../../consts";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";
import {
  getInvitation,
  createTeam,
  joinTeam,
  declineInvitation,
} from "../../store/actions";
import { teamNameIssues } from "../../store/validators";
import Login from "../navigation/login";
import Progress from "../presentational/progress";
import ErrorMessage from "../presentational/errorMessage";
import PanelHeader from "../presentational/panelHeader";
import { TextField, Grid, Button, Typography, Link } from "@material-ui/core";

export default function Join(props) {
  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(1),
      textDecoration: "underline",
    },
    section: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "100%",
    },
    commands: {
      margin: "0px",
    },
    progress: {
      width: "100%",
    },
    message: {
      marginBottom: theme.spacing(2),
    },
    description: {
      marginBottom: theme.spacing(2),
    },
    warning: {
      color: theme.palette.alert.error,
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { gameID, teamID, invitationID } = useParams();
  const history = useHistory();
  const [teamName, setTeamName] = useState("");
  const [teamNameError, setTeamNameError] = useState("");
  const [missingGameID, setMissingGameID] = useState(gameID);
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState({ game: {}, team: {} });
  const [busy, setBusy] = useState(true);

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      setBusy(true);
      getInvitation(
        gameID,
        teamID,
        invitationID,
        getAccessTokenSilently,
        dispatch
      )
        .then((getInvitationResult) => {
          setInvitation(getInvitationResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : `Error: There was an issue getting the invitation.`
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading]);

  const joinNewTeam = () => {
    let teamNameError = teamNameIssues(teamName);
    if (teamNameError) {
      setTeamNameError(teamNameError);
    } else {
      setBusy(true);
      createTeam(invitation.game.id, teamName, getAccessTokenSilently, dispatch)
        .then(() => {
          history.push(
            `${consts.PANEL_GAME}/${invitation.game.id}${consts.PANEL_GAME_WELCOME}`
          );
        })
        .catch((exception) => {
          setTeamNameError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue creating a new team."
          );
          setBusy(false);
        });
    }
  };

  const renderNewTeam = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.section}>
          <TextField
            id="teamNameInput"
            required={true}
            error={teamNameError ? true : false}
            fullWidth={true}
            helperText={teamNameError ? teamNameError : "Choose a team name"}
            label="Team Name"
            value={teamName}
            onChange={(event) => {
              setTeamName(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                joinNewTeam();
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item container xs={12} spacing={2} className={classes.commands}>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              fullWidth={true}
              color="primary"
              variant="outlined"
              onClick={() => {
                setBusy(true);
                declineInvitation(
                  invitation.game.id,
                  invitation.id,
                  getAccessTokenSilently,
                  dispatch
                )
                  .then(() => {
                    history.push(`${consts.PANEL_MAIN}`);
                  })
                  .catch((exception) => {
                    setError(
                      exception.response && exception.response.data
                        ? exception.response.data
                        : "Error: There was an issue removing the invitation."
                    );
                    setBusy(false);
                  });
              }}
            >
              Decline
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              fullWidth={true}
              disabled={teamName.length == 0}
              color="secondary"
              variant="outlined"
              onClick={() => {
                joinNewTeam();
              }}
            >
              Join
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderExistingTeam = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.section}>
          <Typography variant="body1">
            You have been invited to play this game on an existing team{" "}
            <b>"{invitation.team.title}"</b>. You cannot change teams once
            you've accepted an invitation so be sure this is the team you want
            to play for.
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2} className={classes.commands}>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              color="primary"
              fullWidth={true}
              variant="outlined"
              onClick={() => {
                setBusy(true);
                declineInvitation(
                  invitation.game.id,
                  invitation.id,
                  getAccessTokenSilently,
                  dispatch
                )
                  .then(() => {
                    history.push(`${consts.PANEL_MAIN}`);
                  })
                  .catch((exception) => {
                    setError(
                      exception.response && exception.response.data
                        ? exception.response.data
                        : "Error: There was an issue declining the invitation."
                    );
                    setBusy(false);
                  });
              }}
            >
              Decline
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth={true}
              color="secondary"
              variant="outlined"
              onClick={() => {
                setBusy(true);
                joinTeam(
                  invitation.game.id,
                  invitation.team.id,
                  invitation.id,
                  getAccessTokenSilently,
                  dispatch
                )
                  .then(() => {
                    history.push(
                      `${consts.PANEL_GAME}/${invitation.game.id}${consts.PANEL_GAME_WELCOME}`
                    );
                  })
                  .catch((exception) => {
                    setError(
                      exception.response && exception.response.data
                        ? exception.response.data
                        : "Error: There was an issue joining this team."
                    );
                    setBusy(false);
                  });
              }}
            >
              Join
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderTeamInputs = () => {
    return (
      <Grid item container xs={12} className={classes.section}>
        {invitation.team && invitation.team.id
          ? renderExistingTeam()
          : renderNewTeam()}
      </Grid>
    );
  };

  const renderAvailable = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            Join {invitation?.team?.id ? "Team" : "Game"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${invitation.game.id}/banner.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${invitation.game.id}/banner.jpg`.toLowerCase()}
              alt="Game Banner Image"
              className={classes.image}
            />
          </picture>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" align="center" className={classes.subtitle}>
            {invitation.game.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {invitation.game.description}
          </Typography>
          {!isAuthenticated && (
            <Typography variant="body1" className={classes.warning}>
              You must login or signup for a new account to join this game.
            </Typography>
          )}
          {!isAuthenticated && <Login />}
          {isAuthenticated && renderTeamInputs()}
        </Grid>
      </Grid>
    );
  };

  const renderPlaying = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            Currently Playing
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${invitation.game.id}/banner.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${invitation.game.id}/banner.jpg`.toLowerCase()}
              alt="Game Banner Image"
              className={classes.image}
            />
          </picture>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" align="center" className={classes.subtitle}>
            {invitation.game.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {invitation.game.description}
          </Typography>
          <Typography variant="body1" className={classes.warning}>
            You've already joined this game. Click Open below to go to the
            game's main page.
          </Typography>
          <Grid item container xs={12} spacing={2} className={classes.commands}>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                fullWidth={true}
                color="primary"
                variant="outlined"
                onClick={() => {
                  history.push(`${consts.PANEL_MAIN}`);
                }}
              >
                Home
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                fullWidth={true}
                color="secondary"
                variant="outlined"
                onClick={() => {
                  history.push(`${consts.PANEL_GAME}/${invitation.game.id}`);
                }}
              >
                Open
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderClosed = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            Game Ended
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <picture>
            <source
              srcSet={`${consts.BLOB_URL}${invitation.game.id}/banner.webp`.toLowerCase()}
              type="image/webp"
            />
            <img
              src={`${consts.BLOB_URL}${invitation.game.id}/banner.jpg`.toLowerCase()}
              alt="Game Banner Image"
              className={classes.image}
            />
          </picture>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" align="center" className={classes.subtitle}>
            {invitation.game.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {invitation.game.description}
          </Typography>
          <Typography variant="body1" className={classes.warning}>
            This game is no longer accepting new players.
          </Typography>
          <Button
            className={classes.button}
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              history.push(`${consts.PANEL_MAIN}`);
            }}
          >
            Home
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderGameNotFound = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            Game Not Found
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className={classes.message}>
            We're unable to find an active game with the ID <b>"{gameID}"</b>.
            Please check that you've entered the correct Game ID and try again.
            If you continue to experience issues you can contact our support
            team for help at
            <br />
            <Link href="mailto:support@cluesnaps.com">
              support@cluesnaps.com
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="gameIDInput"
            fullWidth={true}
            required={true}
            label="Game ID"
            value={missingGameID}
            onChange={(event) => {
              console.log(missingGameID);
              setMissingGameID(
                event.target.value.toUpperCase().replace(/\s+/g, "")
              );
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && missingGameID) {
                event.preventDefault();
                history.push(`${consts.PANEL_JOIN}/${missingGameID}`);
              }
            }}
            variant="outlined"
          />
          <Grid item container xs={12} spacing={2} className={classes.commands}>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                fullWidth={true}
                color="primary"
                variant="outlined"
                onClick={() => {
                  history.push(`${consts.PANEL_MAIN}`);
                }}
              >
                Home
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                disabled={missingGameID.length == 0}
                fullWidth={true}
                color="secondary"
                variant="outlined"
                onClick={() => {
                  if (missingGameID) {
                    history.push(`${consts.PANEL_JOIN}/${missingGameID}`);
                  }
                }}
              >
                Retry
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDetails = () => {
    if (invitation && invitation.game) {
      switch (invitation.game.status) {
        case consts.GAME_STATUS_AVAILABLE:
          return renderAvailable();
        case consts.GAME_STATUS_PLAYING:
          return renderPlaying();
        case consts.GAME_STATUS_CLOSED:
          return renderClosed();
        default:
          return renderGameNotFound();
      }
    } else {
      return renderGameNotFound();
    }
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {/* <ErrorMessage message={error} /> */}
      <Progress busy={busy} />
      {!busy && renderDetails()}
    </Grid>
  );
}
