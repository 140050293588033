import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import { useParams, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardHeader,
  IconButton,
  CardActions,
  Collapse,
  CardContent,
  Badge,
  TextField,
  Button,
  Divider,
  Link,
} from "@material-ui/core";
import {
  Favorite as HeartIcon,
  Chat as CommentsIcon,
} from "@material-ui/icons";
import { getFeed, getComments, addComment } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";

export default function Feed(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    activityWrapper: {
      margin: "auto",
    },
    commentsWrapper: {
      margin: "auto",
    },
    messagesWrapper: {
      margin: "auto",
    },
    activity: {
      [theme.breakpoints.down("xs")]: {
        padding: "8px 0 !important",
      },
    },
    action: {
      marginTop: "0px",
      marginRight: "0px",
    },
    submit: {},
    image: {
      width: "100%",
    },
    signature: {
      textAlign: "right",
    },
    helperText: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState([]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getFeed(state.game.id, getAccessTokenSilently, dispatch)
        .then((getFeedResult) => {
          setActivities(getFeedResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the feed."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const renderActivity = (activity) => {
    return (
      <Grid
        key={activity.clueID + activity.teamID}
        item
        xs={12}
        sm={6}
        lg={4}
        className={classes.activity}
      >
        <LazyLoad height="468" offset={100}>
          <Card
            raised={true}
            id={`${activity.clueID}-${activity.teamID}`}
            className={classes.card}
          >
            <CardHeader
              classes={{ action: classes.action }}
              action={
                activity.voteCount > 0 && (
                  <Badge
                    badgeContent={activity.voteCount}
                    color="primary"
                    classes={{ badge: classes.badge }}
                  >
                    <HeartIcon className={classes.heart} />
                  </Badge>
                )
              }
              title={activity.clueTitle}
              subheader={activity.teamTitle}
            />
            <LazyLoad once>
              <picture>
                <source
                  srcSet={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${activity.clueID}-${activity.teamID}.webp`.toLowerCase()}
                  type="image/webp"
                />
                <img
                  src={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${activity.clueID}-${activity.teamID}.jpg`.toLowerCase()}
                  alt={`${activity.teamTitle} - ${activity.clueTitle}`}
                  className={classes.image}
                />
              </picture>
            </LazyLoad>
            <CardActions disableSpacing>
              {state.game.commentsEnabled && (
                <Link
                  component={NavLink}
                  to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_FEED}/${activity.clueID}/${activity.teamID}`}
                  activeClassName={classes.selected}
                  color="inherit"
                >
                  <IconButton>
                    <Badge
                      badgeContent={activity.commentCount}
                      color="primary"
                      classes={{ badge: classes.badge }}
                    >
                      <CommentsIcon />
                    </Badge>
                  </IconButton>
                </Link>
              )}
            </CardActions>
          </Card>
        </LazyLoad>
      </Grid>
    );
  };

  const renderActivities = () => {
    return (
      <Grid
        item
        container
        xs={12}
        spacing={2}
        className={classes.activityWrapper}
      >
        {activities.length > 0 ? (
          activities.map((activity) => {
            return renderActivity(activity);
          })
        ) : (
          <Typography variant="body1">
            {!busy &&
              "No one has done anything exciting yet. Go take some photos already."}
          </Typography>
        )}
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderActivities()}
    </Grid>
  );
}
