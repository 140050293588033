import React, { useContext, useState, useEffect, useRef } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Switch,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
  LinearProgress,
  Button,
  Link,
  TextField,
} from "@material-ui/core";
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import {
  setDarkMode,
  saveAppNotificationsSettings,
  saveEmailNotificationsSettings,
  resendEmailVerification,
  updateEmailAddress,
  resetPassword,
} from "../../store/actions";
import Login from "../navigation/login";
import * as consts from "../../consts";
import ErrorMessage from "../presentational/errorMessage";
import Progress from "../presentational/progress";
import LegalLinks from "../navigation/legalLinks";
import PanelHeader from "../presentational/panelHeader";

export default function Settings(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      display: "inline-block",
      "& p": { fontSize: ".75rem" },
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (state.settings) {
      if (state.settings.id) {
        setBusy(false);
      }
    }
  }, [state.settings]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  const emailIssues = () => {
    if (!email || email.length == 0) {
      return "Email is required";
    } else if (!consts.VALIDATION_EMAIL.test(email)) {
      return "Not a valid email address";
    } else {
      return null;
    }
  };

  const submitEmailAddress = () => {
    setInvalidEmail("");
    let emailError = emailIssues();
    if (emailError) {
      setInvalidEmail(emailError);
    } else {
      setBusy(true);
      updateEmailAddress(email, getAccessTokenSilently, dispatch)
        .then(() => {
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue saving your email."
          );
          setBusy(false);
        });
    }
  };

  const renderNotificationInputs = () => {
    return (
      <FormGroup className={classes.section}>
        <FormControlLabel
          control={
            <Switch
              checked={state.darkMode}
              disabled={busy}
              onChange={(event) => {
                setBusy(true);
                setDarkMode(event.target.checked, dispatch).then(() => {
                  setBusy(false);
                });
              }}
            />
          }
          label="Dark Mode"
        />
        {"serviceWorker" in navigator && "PushManager" in window && (
          <FormControlLabel
            control={
              <Switch
                checked={state.settings.appNotifications}
                disabled={busy}
                onChange={(event) => {
                  setBusy(true);
                  saveAppNotificationsSettings(
                    event.target.checked,
                    getAccessTokenSilently,
                    dispatch
                  ).then(() => {
                    setBusy(false);
                  });
                }}
              />
            }
            label="App Notifications"
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={state.settings.emailNotifications}
              disabled={busy}
              onChange={(event) => {
                setBusy(true);
                saveEmailNotificationsSettings(
                  event.target.checked,
                  getAccessTokenSilently,
                  dispatch
                ).then(() => {
                  setBusy(false);
                });
              }}
            />
          }
          label="Email Notifications"
        />
      </FormGroup>
    );
  };

  const renderEmailInputs = () => {
    return (
      <Grid container item xs={12} className={classes.section} spacing={2}>
        {!user.email_verified && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="secondary"
              component="div"
              style={{ textAlign: "justify" }}
            >
              You have not verified your email address. You will need to
              complete this process before you are allowed to comment, vote, and
              upload photos. If you are having trouble with this step you can
              reach out to{" "}
              <Link
                href={`mailto:support@cluesnaps.com`}
                className={classes.link}
              >
                <Typography variant="body1">support@cluesnaps.com</Typography>
              </Link>{" "}
              for help.
            </Typography>
            <Button
              disabled={busy}
              fullWidth={true}
              variant="outlined"
              color="secondary"
              onClick={() => {
                resendEmailVerification(getAccessTokenSilently, dispatch);
              }}
            >
              Resend Verification Email
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="emailInput"
            disabled={busy}
            required={true}
            error={invalidEmail ? true : false}
            fullWidth={true}
            helperText={invalidEmail}
            label="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                submitEmailAddress();
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          {state.settings.emailAddress != email && (
            <Button
              disabled={busy}
              fullWidth={true}
              variant="outlined"
              color="primary"
              onClick={() => {
                submitEmailAddress();
              }}
            >
              Update Email Address
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderPasswordInputs = () => {
    return (
      <Grid container item xs={12} className={classes.section} spacing={2}>
        <Grid item xs={12}>
          <Button
            disabled={busy}
            fullWidth={true}
            variant="outlined"
            color="primary"
            onClick={() => {
              resetPassword(getAccessTokenSilently, dispatch);
            }}
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderSettings = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12} sm={6}>
          {!busy && user && renderNotificationInputs()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {!busy && user && renderEmailInputs()}
          {!busy && user && renderPasswordInputs()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      <PanelHeader title="Settings" />
      <ErrorMessage message={error} />
      <Progress busy={busy} />
      {isAuthenticated ? renderSettings() : <Login />}
      <LegalLinks />
    </Grid>
  );
}
