export const SERVICE_URL = process.env.SERVICE_URL;
export const SITE_URL = process.env.SITE_URL;
export const BLOB_URL = process.env.BLOB_URL;
export const SHARED_URL = process.env.SHARED_URL;
export const VAPID_PUBLIC_KEY = process.env.VAPID_PUBLIC_KEY;
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE;
export const AUTH0_CLIENTID = process.env.AUTH0_CLIENTID;
export const AUTH0_SCOPE =
  "openid profile join:teams join:games add:comments add:votes add:clues add:invitations add:settings remove:invitations remove:votes remove:notifications read:games read:teams read:invitations read:clues read:candidates read:votes read:comments read:leaderboards read:notifications read:settings";

export const RESOURCE_URL_PRIVACY = process.env.RESOURCE_URL_PRIVACY;
export const RESOURCE_URL_TERMS = process.env.RESOURCE_URL_TERMS;
export const RESOURCE_URL_COOKIES = process.env.RESOURCE_URL_COOKIES;
export const RESOURCE_URL_ABOUT = process.env.RESOURCE_URL_ABOUT;

export const PANEL_EMPTY = "/";
export const PANEL_MAIN = "/";
export const PANEL_ABOUT = "/About";
export const PANEL_PRIVACY = "/Privacy";
export const PANEL_COOKIES = "/Cookies";
export const PANEL_TERMS = "/Terms";
export const PANEL_FAQ = "/FAQ";
export const PANEL_SETTINGS = "/Settings";
export const PANEL_JOIN = "/Join";
export const PANEL_GAME = "/Game";
export const PANEL_GAME_WELCOME = "/Welcome";
export const PANEL_GAME_CLUES = "/Clues";
export const PANEL_GAME_TEAM = "/Team";
export const PANEL_GAME_FEED = "/Feed";
export const PANEL_GAME_VOTES = "/Votes";
export const PANEL_GAME_RULES = "/Rules";
export const PANEL_GAME_SCORE = "/Score";
export const PANEL_GAME_SPONSORS = "/Sponsors";
export const PANEL_GAME_PRIZES = "/Prizes";

export const ACTION_SET_BUSY = "ACTION_SET_BUSY";
export const ACTION_SET_DARK_MODE = "ACTION_SET_DARK_MODE";
export const ACTION_ADD_ALERT = "ACTION_ADD_ALERT";
export const ACTION_DISMISS_ALERT = "ACTION_DISMISS_ALERT";
export const ACTION_SET_GAME = "ACTION_SET_GAME";
export const ACTION_SET_NOTIFICATIONS = "ACTION_SET_NOTIFICATIONS";
export const ACTION_REMOVE_NOTIFICATION = "ACTION_REMOVE_NOTIFICATION";
export const ACTION_SET_SETTINGS = "ACTION_SET_SETTINGS";

export const STORAGE_DARK_MODE = "STORAGE_DARK_MODE";

export const GAME_STATUS_AVAILABLE = "Available";
export const GAME_STATUS_PLAYING = "Playing";
export const GAME_STATUS_CLOSED = "Closed";

export const CLUE_STATUS_REQUIRED = "Required";
export const CLUE_STATUS_PENDING = "Pending";
export const CLUE_STATUS_APPROVED = "Approved";
export const CLUE_STATUS_REJECTED = "Rejected";

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";

export const GAME_THUMBNAIL_PLACEHOLDER_URL =
  BLOB_URL + "default/game_thumbnail.jpg";

export const VALIDATION_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const THEME_DARK = {
  palette: {
    type: "dark",
    primary: {
      main: "#03a9f4",
    },
    secondary: {
      main: "#ff9100",
    },
    alert: {
      error: "#b2102f",
      success: "#52b202",
    },
    icons: {
      default: "#ffffff",
      home: "#ffffff",
      join: "#ffffff",
      about: "#ffffff",
      faq: "#ffffff",
      privacy: "#ffffff",
      cookies: "#ffffff",
      terms: "#ffffff",
      settings: "#ffffff",
      game: "#ff9100",
      welcome: "#ff9100",
      clues: "#ef6694",
      team: "#ab47bc",
      feed: "#008fcc",
      votes: "#dc2727",
      rules: "#00a152",
      score: "#9496a7",
      sponsors: "#26a69a",
      prizes: "#ef6c00",
      heart: "#f73378",
      badge: "#a6d4fa",
      teamHeart: "#bdbdbd",
      disabled: "#bdbdbd",
      required: "#03a9f4",
      rejected: "#b2102f",
    },
  },
};

export const THEME_LIGHT = {
  palette: {
    type: "light",
    primary: {
      main: "#03a9f4",
    },
    secondary: {
      main: "#ff9100",
    },
    alert: {
      error: "#b2102f",
      success: "#52b202",
    },
    icons: {
      default: "#03a9f4",
      home: "#03a9f4",
      join: "#03a9f4",
      about: "#03a9f4",
      faq: "#03a9f4",
      privacy: "#03a9f4",
      cookies: "#03a9f4",
      terms: "#03a9f4",
      settings: "#03a9f4",
      game: "#ff9100",
      welcome: "#ff9100",
      clues: "#ef6694",
      team: "#ab47bc",
      feed: "#008fcc",
      votes: "#d32f2f",
      rules: "#00a152",
      score: "#283593",
      sponsors: "#26a69a",
      prizes: "#ef6c00",
      heart: "#f73378",
      badge: "#a6d4fa",
      teamHeart: "#bdbdbd",
      disabled: "#bdbdbd",
      required: "#03a9f4",
      rejected: "#b2102f",
    },
  },
};
