import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import {
  Grid,
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  IconButton,
  CardActions,
  GridList,
  GridListTile,
  GridListTileBar,
} from "@material-ui/core";
import {
  Favorite as VotedIcon,
  FavoriteBorder as NotVotedIcon,
  ExpandMore as ExpandMoreIcon,
  NotInterested as OwnTeamIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getClues,
  getVotes,
  getCandidates,
  addVote,
  removeVote,
} from "../../store/actions";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";
import { useParams, useHistory, NavLink } from "react-router-dom";

export default function Candidates(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    hearts: {
      textAlign: "right",
      marginBottom: theme.spacing(1),
    },
    teamHeart: {
      color: theme.palette.icons.teamHeart,
    },
    clueTitle: {
      fontSize: "2rem",
      marginBottom: theme.spacing(2),
    },
    disabled: {
      color: theme.palette.icons.disabled,
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    notvoted: {
      color: theme.palette.icons.heart,
    },
    categoryWrapper: {
      margin: "auto",
    },
    image: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    cardActions: {
      flexDirection: "row-reverse",
    },
    candidates: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {},
    gridTile: {
      height: "auto !important",
    },
    listItem: {
      height: "auto !important",
    },
    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    voted: {
      color: theme.palette.icons.heart,
      animation: "$ripple 1.2s infinite ease-in-out",
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { clueID } = useParams();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [clues, setClues] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [votes, setVotes] = useState([]);
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getClues(state.game.id, getAccessTokenSilently, dispatch)
        .then((getCluesResult) => {
          setClues(getCluesResult);
          getCandidates(state.game.id, clueID, getAccessTokenSilently, dispatch)
            .then((getCandidatesResult) => {
              let sortedCandidates = getCandidatesResult
                .sort(() => Math.random() - 0.5)
                .sort((a, b) => {
                  return state.game.team.id == a.teamID ? -1 : 1;
                });
              let nonFeaturedTrend = 0;
              sortedCandidates.map((candidate, index) => {
                if (index == 0) {
                  candidate.featured = true;
                } else {
                  if (nonFeaturedTrend % 2) {
                    candidate.featured = false;
                    nonFeaturedTrend++;
                  } else {
                    let featured = Math.random() > 0.8;
                    candidate.featured = featured;
                    nonFeaturedTrend = featured ? 0 : nonFeaturedTrend + 1;
                  }
                }
              });
              setCandidates(sortedCandidates);
              getVotes(state.game.id, getAccessTokenSilently, dispatch)
                .then((getVotesResult) => {
                  setVotes(getVotesResult);
                  setBusy(false);
                })
                .catch((exception) => {
                  setError(
                    exception.response && exception.response.data
                      ? exception.response.data
                      : "Error: There was an issue getting your votes."
                  );
                  setBusy(false);
                });
            })
            .catch((exception) => {
              setError(
                exception.response && exception.response.data
                  ? exception.response.data
                  : "Error: There was an issue getting the candidates."
              );
              setBusy(false);
            });
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the clues."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const submitVote = (candidate) => {
    if (state.settings.emailVerified) {
      let voted = votes.find((vote) => {
        return (
          vote.teamID == candidate.teamID && vote.clueID == candidate.clueID
        );
      });
      if (voted) {
        setBusy(true);
        removeVote(
          state.game.id,
          candidate.teamID,
          candidate.clueID,
          getAccessTokenSilently,
          dispatch
        )
          .then((vote) => {
            let updatedVotes = votes.slice();
            let voteIndex = 0;
            while (voteIndex < updatedVotes.length) {
              if (
                updatedVotes[voteIndex].teamID == vote.teamID &&
                updatedVotes[voteIndex].clueID == vote.clueID
              ) {
                updatedVotes.splice(voteIndex, 1);
              } else {
                ++voteIndex;
              }
            }
            setVotes(updatedVotes);
            setBusy(false);
          })
          .catch();
      } else if (
        candidate.teamID != state.game.team.id &&
        state.game.voteLimit > votes.length
      ) {
        setBusy(true);
        addVote(
          state.game.id,
          candidate.teamID,
          candidate.clueID,
          getAccessTokenSilently,
          dispatch
        )
          .then((vote) => {
            let updatedVotes = votes.slice();
            updatedVotes.push(vote);
            setVotes(updatedVotes);
            setBusy(false);
          })
          .catch();
      }
    }
  };

  const renderHearts = () => {
    return (
      <Grid
        item
        xs={12}
        className={classes.hearts}
        aria-label={`You have ${
          state.game.voteLimit - votes.length
        } votes left to issue`}
      >
        {[...Array(state.game.voteLimit)].map((e, index) => {
          if (index < votes.length) {
            return <VotedIcon className={classes.heart} key={index} />;
          } else {
            return <NotVotedIcon className={classes.heart} key={index} />;
          }
        })}
      </Grid>
    );
  };

  const renderCandidates = () => {
    let currentClue = clues.find((clue) => {
      return clue.id == clueID;
    });
    return (
      <div className={classes.candidates}>
        <Typography variant="h2" className={classes.clueTitle}>
          {currentClue && currentClue.title}
        </Typography>
        {candidates.length > 0 ? (
          <GridList cellHeight={200} spacing={1} className={classes.gridList}>
            {candidates.map((candidate) => {
              let voted = votes.find((vote) => {
                return (
                  vote.teamID == candidate.teamID &&
                  vote.clueID == candidate.clueID
                );
              });
              return (
                <GridListTile
                  key={candidate.clueID + candidate.teamID}
                  classes={{ root: classes.gridTile, tile: classes.listItem }}
                  cols={candidate.featured ? 2 : 1}
                  rows={candidate.featured ? 2 : 1}
                  onClick={() => {
                    submitVote(candidate);
                  }}
                >
                  <LazyLoad once>
                    <picture>
                      <source
                        srcSet={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${candidate.clueID}-${candidate.teamID}.webp`.toLowerCase()}
                        type="image/webp"
                      />
                      <img
                        src={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${candidate.clueID}-${candidate.teamID}.jpg`.toLowerCase()}
                        alt={candidate.title}
                        className={classes.image}
                      />
                    </picture>
                  </LazyLoad>
                  <GridListTileBar
                    titlePosition="bottom"
                    actionIcon={
                      <IconButton>
                        {candidate.teamID == state.game.team.id ? (
                          <OwnTeamIcon />
                        ) : voted ? (
                          <VotedIcon className={classes.voted} />
                        ) : state.game.voteLimit > votes.length ? (
                          <NotVotedIcon className={classes.notvoted} />
                        ) : (
                          <VotedIcon className={classes.disabled} />
                        )}
                      </IconButton>
                    }
                    actionPosition="right"
                    className={classes.titleBar}
                  />
                </GridListTile>
              );
            })}
          </GridList>
        ) : (
          <Typography variant="body1">
            {!busy &&
              "Your competition hasn't figured out what to do for this clue yet. There is nothing to vote on here."}
          </Typography>
        )}
      </div>
    );
  };

  const renderVoteList = () => {
    return (
      <React.Fragment>
        {renderHearts()}
        {renderCandidates()}
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderVoteList()}
    </Grid>
  );
}
