import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  LinearProgress,
  Avatar,
  Badge,
  Link,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  Favorite as HeartIcon,
  Assignment as RequiredIcon,
  HourglassFull as PendingIcon,
  AssignmentLate as RejectedIcon,
  AssignmentTurnedIn as ApprovedIcon,
} from "@material-ui/icons";
import { getClues } from "../../store/actions";
import { State } from "../../store/state";
import { useHistory, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../../consts";
import LazyLoad from "react-lazyload";

export default function Clues(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: "1.5rem",
    },
    list: {
      width: "100%",
    },
    listItem: {
      width: "100%",
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    required: {
      color: theme.palette.icons.required,
    },
    rejected: {
      color: theme.palette.icons.rejected,
    },
    filter: {
      textAlign: "right",
    },
    approvedBadge: {
      backgroundColor: "#52b202",
      color: "#52b202",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    rejectedBadge: {
      backgroundColor: "#b2102f",
      color: "#b2102f",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [clues, setClues] = useState([]);
  const [busy, setBusy] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      let gameStarted = state.game && new Date(state.game.start) < new Date();
      if (gameStarted) {
        setBusy(true);
        getClues(state.game.id, getAccessTokenSilently, dispatch)
          .then((getCluesResult) => {
            setClues(getCluesResult);
            setBusy(false);
          })
          .catch((exception) => {
            setError(
              exception.response && exception.response.data
                ? exception.response.data
                : "Error: There was an issue getting the clues."
            );
            setBusy(false);
          });
      }
    } else {
      setBusy(false);
    }
  }, [isAuthenticated, isLoading, state.game]);

  const renderClues = () => {
    let complete = true;
    return (
      <Grid container item xs={12}>
        <Grid item xs={12} className={classes.filter}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAll}
                onChange={(event) => {
                  setShowAll(event.target.checked);
                }}
              />
            }
            label="Show All"
          />
        </Grid>
        <Grid item xs={12}>
          <List className={classes.list}>
            {clues.map((clue) => {
              if (showAll || clue.status != consts.CLUE_STATUS_APPROVED) {
                complete = false;
                return (
                  <LazyLoad key={clue.id}>
                    <Link
                      component={NavLink}
                      to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_CLUES}/${clue.id}`}
                      activeClassName={classes.selected}
                      color="inherit"
                    >
                      <ListItem
                        alignItems="flex-start"
                        className={classes.listItem}
                        disableGutters={true}
                      >
                        <ListItemIcon>
                          {clue.status == consts.CLUE_STATUS_REQUIRED && (
                            <RequiredIcon className={classes.required} />
                          )}
                          {clue.status == consts.CLUE_STATUS_PENDING && (
                            <PendingIcon />
                          )}
                          {clue.status == consts.CLUE_STATUS_APPROVED && (
                            <ApprovedIcon />
                          )}
                          {clue.status == consts.CLUE_STATUS_REJECTED && (
                            <RejectedIcon className={classes.rejected} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={clue.title}
                          secondary={clue.status}
                          classes={{
                            secondary:
                              clue.status == consts.CLUE_STATUS_REJECTED
                                ? classes.rejected
                                : "",
                          }}
                        />
                        <ListItemAvatar>
                          <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                            classes={
                              clue.status == consts.CLUE_STATUS_APPROVED
                                ? {
                                    badge: classes.approvedBadge,
                                  }
                                : clue.status == consts.CLUE_STATUS_REJECTED
                                ? { badge: classes.rejectedBadge }
                                : {}
                            }
                          >
                            <Avatar
                              alt={clue.title}
                              srcSet={
                                clue.status != consts.CLUE_STATUS_APPROVED
                                  ? `${consts.SHARED_URL}required_thumbnail.jpg`.toLowerCase()
                                  : `${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${clue.id}-${clue.teamID}_thumbnail.jpg`.toLowerCase()
                              }
                              src={
                                clue.status != consts.CLUE_STATUS_APPROVED
                                  ? `${consts.SHARED_URL}required_thumbnail.jpg`.toLowerCase()
                                  : `${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${clue.id}-${clue.teamID}.jpg`.toLowerCase()
                              }
                              imgProps={{ width: "40", height: "40" }}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          {clue.votes > 0 && (
                            <Badge
                              badgeContent={clue.votes}
                              color="primary"
                              classes={{ badge: classes.badge }}
                            >
                              <HeartIcon className={classes.heart} />
                            </Badge>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                    <Divider variant="middle" component="li" />
                  </LazyLoad>
                );
              } else {
                return null;
              }
            })}
          </List>
          {!busy && complete && (
            <Typography variant="body1">
              Awesome job!
              <br />
              You've completed all the clues.
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderClues()}
    </Grid>
  );
}
