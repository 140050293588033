import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Divider,
  Button,
  TextField,
  IconButton,
  Paper,
} from "@material-ui/core";
import {
  People as MemberIcon,
  Star as CaptainIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  getTeamMembership,
  getTeamInvitations,
  addTeamInvitation,
  removeTeamInvitation,
} from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../../consts";

export default function Team(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    heading: {
      fontSize: "1.5rem",
    },
    title: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(1),
    },
    list: {
      width: "100%",
    },
    progress: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState("");
  const [email, setEmail] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamInvitations, setTeamInvitations] = useState([]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getTeamMembership(
        state.game.id,
        state.game.team.id,
        getAccessTokenSilently,
        dispatch
      )
        .then((getTeamMembershipResult) => {
          setTeamMembers(getTeamMembershipResult);
          getTeamInvitations(
            state.game.id,
            state.game.team.id,
            getAccessTokenSilently,
            dispatch
          )
            .then((getTeamInvitationsResult) => {
              setTeamInvitations(getTeamInvitationsResult);
              setBusy(false);
            })
            .catch((exception) => {
              setError(
                exception.response && exception.response.data
                  ? exception.response.data
                  : "Error: There was an issue getting the team invitations."
              );
              setBusy(false);
            });
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the team members."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const emailIssues = () => {
    if (!email || email.length == 0) {
      return "Email is required";
    } else if (!consts.VALIDATION_EMAIL.test(email)) {
      return "Not a valid email address";
    } else {
      let duplicateTeamMembers = teamMembers.find((teamMember) => {
        return teamMember.email.toLowerCase() == email.toLowerCase();
      });
      if (duplicateTeamMembers) {
        return "This user is already a member of the team";
      } else {
        let duplicateInvitations = teamInvitations.find((teamInvitation) => {
          return teamInvitation.email.toLowerCase() == email.toLowerCase();
        });
        if (duplicateInvitations) {
          return "This user has already been sent an invitation";
        } else {
          return null;
        }
      }
    }
  };

  const submitInvitation = () => {
    setInvalidEmail("");
    let emailError = emailIssues();
    if (emailError) {
      setInvalidEmail(emailError);
    } else {
      setBusy(true);
      addTeamInvitation(
        state.game.id,
        state.game.team.id,
        email,
        getAccessTokenSilently,
        dispatch
      )
        .then((teamInvitation) => {
          let updatedTeamInvitations = teamInvitations.slice();
          updatedTeamInvitations.push(teamInvitation);
          setTeamInvitations(updatedTeamInvitations);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue saving the team invitation."
          );
          setBusy(false);
        });
    }
  };

  const renderTeamMembers = () => {
    return (
      <Grid item container xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.heading}>
          Team Members
        </Typography>
        <List className={classes.list}>
          {teamMembers.map((teamMember) => {
            return (
              <React.Fragment key={teamMember.id}>
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    {state.game.team.captain == teamMember.id ? (
                      <CaptainIcon />
                    ) : (
                      <MemberIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{teamMember.name}</Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        {teamMember.email}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </Grid>
    );
  };

  const renderTeamInvitations = () => {
    return (
      <Grid item container xs={12} className={classes.section}>
        <Typography variant="h2" className={classes.heading}>
          Invitations
        </Typography>
        <List className={classes.list}>
          {teamInvitations.map((teamInvitation) => {
            let sentDate = new Date(teamInvitation.sent);
            return (
              <React.Fragment key={teamInvitation.id}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {teamInvitation.email}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        {sentDate.toDateString()}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setBusy(true);
                        removeTeamInvitation(
                          state.game.id,
                          state.game.team.id,
                          teamInvitation.id,
                          getAccessTokenSilently,
                          dispatch
                        )
                          .then((teamInvitation) => {
                            let updatedTeamInvitations = teamInvitations.slice();
                            let teamInvitationIndex = 0;
                            while (
                              teamInvitationIndex <
                              updatedTeamInvitations.length
                            ) {
                              if (
                                updatedTeamInvitations[teamInvitationIndex]
                                  .id == teamInvitation.id
                              ) {
                                updatedTeamInvitations.splice(
                                  teamInvitationIndex,
                                  1
                                );
                              } else {
                                ++teamInvitationIndex;
                              }
                            }
                            setTeamInvitations(updatedTeamInvitations);
                            setBusy(false);
                          })
                          .catch((exception) => {
                            setError(
                              exception.response && exception.response.data
                                ? exception.response.data
                                : "Error: There was an issue removing the team invitation."
                            );
                            setBusy(false);
                          });
                      }}
                    >
                      <DeleteIcon edge="end" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="middle" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </Grid>
    );
  };

  const renderInviteTeamMember = () => {
    return (
      <Grid item container xs={12} className={classes.section}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.title}>
            Send Invitation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="emailInput"
            required={true}
            disabled={
              state.game.teamSizeLimit <=
              teamMembers.length + teamInvitations.length
            }
            error={invalidEmail ? true : false}
            fullWidth={true}
            helperText={invalidEmail}
            label="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                submitInvitation();
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            fullWidth={true}
            disabled={
              state.game.teamSizeLimit <=
              teamMembers.length + teamInvitations.length
            }
            color="secondary"
            variant="outlined"
            onClick={submitInvitation}
          >
            Send Invite
          </Button>
        </Grid>
        {state.game.teamSizeLimit <=
          teamMembers.length + teamInvitations.length && (
          <Grid item xs={12}>
            <Typography variant="caption">
              Your team has reached this game's size limit of{" "}
              {state.game.teamSizeLimit}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTeam = () => {
    return (
      <React.Fragment>
        {renderTeamMembers()}
        {teamInvitations.length > 0 && renderTeamInvitations()}
        {renderInviteTeamMember()}
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderTeam()}
    </Grid>
  );
}
