import React, { useEffect, useState, useContext, useCallback } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Badge,
  Paper,
  Button,
  Slider,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import { Favorite as HeartIcon } from "@material-ui/icons";
import { getClue, saveClue } from "../../store/actions";
import Cropper from "react-easy-crop";
import Dropzone from "react-dropzone";
import { State } from "../../store/state";
import { useParams, useHistory, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../../consts";
const reducer = require("image-blob-reduce")();

export default function Clue(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: "1.5rem",
      marginTop: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    upload: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& div": {
        outline: "none",
      },
    },
    cropper: {
      position: "relative !important",
      height: "30vh",
    },
    submit: {
      marginTop: theme.spacing(1),
    },
    link: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    clues: {
      color: theme.palette.icons.clues,
    },
    breadcrumb: {
      marginBottom: theme.spacing(2),
    },
    controls: {
      paddingTop: theme.spacing(1),
    },
    slider: {
      padding: "0px",
    },
    paperLeft: {
      padding: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    paperRight: {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { clueID } = useParams();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [clue, setClue] = useState({});
  const history = useHistory();
  const [busy, setBusy] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [showConfirm, setShowConfirm] = useState(false);

  const [src, setSRC] = useState(null);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getClue(state.game.id, clueID, getAccessTokenSilently, dispatch)
        .then((getClueResult) => {
          setClue(getClueResult);
          if (getClueResult.status != consts.CLUE_STATUS_REQUIRED) {
            setSRC(
              `${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${getClueResult.id}-${getClueResult.teamID}.jpg`.toLowerCase()
            );
          }
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting this clue."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const getCroppedImg = (imageSrc, pixelCrop, rotation = 0) => {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.addEventListener("load", () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let maxSize = Math.max(image.width, image.height);
        let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
        canvas.width = safeArea;
        canvas.height = safeArea;
        ctx.translate(safeArea / 2, safeArea / 2);
        let radianAngle = (rotation * Math.PI) / 180;
        ctx.rotate(radianAngle);
        ctx.translate(-safeArea / 2, -safeArea / 2);
        ctx.drawImage(
          image,
          safeArea / 2 - image.width * 0.5,
          safeArea / 2 - image.height * 0.5
        );
        let data = ctx.getImageData(0, 0, safeArea, safeArea);
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        ctx.putImageData(
          data,
          Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
          Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        );
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
      image.addEventListener("error", (error) => {
        reject(error);
      });
      image.setAttribute("crossOrigin", "anonymous");
      image.src = imageSrc;
    });
  };

  const submitClue = () => {
    let gameEnded = state.game && new Date(state.game.end) < new Date();
    if (!gameEnded && state.settings.emailVerified) {
      setBusy(true);
      getCroppedImg(src, croppedAreaPixels, rotation)
        .then((blob) => {
          saveClue(
            state.game.id,
            clue.id,
            blob,
            getAccessTokenSilently,
            dispatch
          )
            .then(() => {
              history.push(
                `${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_CLUES}`
              );
            })
            .catch((exception) => {
              setError(
                exception.response && exception.response.data
                  ? exception.response.data
                  : "Error: There was an issue saving this clue."
              );
              setBusy(false);
            });
        })
        .catch((exception) => {
          // setAlert(
          //   {
          //     message: "Failed to submit clue",
          //     type: consts.ALERT_TYPE_ERROR,
          //   },
          //   dispatch
          // );
        });
    }
  };

  const renderZoom = () => {
    return (
      <Grid item xs={6}>
        <Paper className={classes.paperLeft}>
          <Typography align="center">ZOOM</Typography>
          <Slider
            className={classes.slider}
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(event, value) => {
              setZoom(value);
              setShowSubmit(true);
            }}
          />
        </Paper>
      </Grid>
    );
  };

  const renderRotate = () => {
    return (
      <Grid item xs={6}>
        <Paper className={classes.paperRight}>
          <Typography align="center">ROTATE</Typography>
          <Slider
            className={classes.slider}
            value={rotation}
            min={0}
            max={360}
            step={1}
            onChange={(event, value) => {
              setRotation(value);
              setShowSubmit(true);
            }}
          />
        </Paper>
      </Grid>
    );
  };

  const renderPreview = () => {
    return (
      <Grid item xs={12}>
        <Cropper
          classes={{ containerClassName: classes.cropper }}
          image={src}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={(value) => {
            setCrop(value);
          }}
          onRotationChange={(value) => {
            setRotation(value);
          }}
          onZoomChange={(value) => {
            setZoom(value);
          }}
          onInteractionEnd={() => {
            setShowSubmit(true);
          }}
          onCropComplete={onCropComplete}
        />
        <Grid item container xs={12} className={classes.controls}>
          {renderZoom()}
          {renderRotate()}
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog disableBackdropClick open={showConfirm}>
        <DialogTitle>Upload Photo</DialogTitle>
        <DialogContent dividers>
          You are replacing an existing photo that players have already voted
          for. If you continue this action all votes applied to this photo will
          be returned.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setShowConfirm(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              submitClue();
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSubmit = () => {
    let gameEnded = state.game && new Date(state.game.end) < new Date();
    if (!gameEnded && state.settings.emailVerified) {
      return (
        <Grid item xs={12}>
          <Button
            onClick={() => {
              if (clue.votes > 0) {
                setShowConfirm(true);
              } else {
                submitClue();
              }
            }}
            variant="outlined"
            color="primary"
            fullWidth={true}
            className={classes.submit}
          >
            <Typography variant="h6">Submit</Typography>
          </Button>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderUpload = () => {
    let gameEnded = state.game && new Date(state.game.end) < new Date();
    if (!gameEnded && state.settings.emailVerified) {
      return (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth={true}
            color="secondary"
            className={classes.upload}
            disabled={busy}
          >
            <Dropzone
              accept="image/*"
              multiple={false}
              onDrop={(files) => {
                if (files && files.length > 0) {
                  // const reader = new FileReader();
                  // reader.addEventListener("load", () => {
                  //   setSRC(reader.result);
                  //   setShowSubmit(true);
                  // });
                  // reader.readAsDataURL(files[0]);

                  reducer.toBlob(files[0], { max: 2048 }).then((blob) => {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                      setSRC(reader.result);
                      setShowSubmit(true);
                    });
                    reader.readAsDataURL(blob);
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography variant="h6">UPLOAD PHOTO</Typography>
                  </div>
                );
              }}
            </Dropzone>
          </Button>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderClue = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.title}>
            {clue.title}
            {clue.votes > 0 && (
              <Badge
                badgeContent={clue.votes}
                color="primary"
                classes={{ badge: classes.badge }}
              >
                <HeartIcon className={classes.heart} />
              </Badge>
            )}
          </Typography>
          <Typography variant="caption">{clue.status}</Typography>
          {clue.status == consts.CLUE_STATUS_REJECTED && (
            <Typography variant="caption" component="p">
              {clue.notes}
            </Typography>
          )}
        </Grid>
        {clue.id && renderUpload()}
        {src && renderPreview()}
        {showSubmit && !busy && renderSubmit()}
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {renderConfirmationDialog()}
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderClue()}
    </Grid>
  );
}
