import * as consts from "../consts";

export function teamNameIssues(teamName) {
  if (!teamName || teamName.length == 0) {
    return "Team Name cannot be empty";
  } else {
    if (teamName && teamName.length > 16) {
      return "Team Name cannot be greater than 16 characters";
    } else {
      return null;
    }
  }
}

export function emailIssues(email) {
  if (!email || email.length == 0) {
    return "Email is required";
  } else if (!consts.VALIDATION_EMAIL.test(email)) {
    return "Not a valid email address";
  } else {
    return null;
  }
}
