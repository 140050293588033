import React, { useState } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Avatar, Paper } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import * as consts from "../../consts";

export default function JoinGlobal() {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.5rem",
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.paper}>
      <Grid item container xs={12}>
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h3" className={classes.header}>
            Join Our Global Game
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.section}>
          <Typography variant="body1">
            Don't have a Game ID yet? Check out our free to play Global game to
            see how things work. It is a great way to get started and it runs
            all year long with a new clue added every month.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            fullWidth={true}
            color="secondary"
            variant="outlined"
            onClick={() => {
              history.push(`${consts.PANEL_JOIN}/GLOBAL`);
            }}
            startIcon={
              <Avatar
                alt=""
                src={`${consts.BLOB_URL}GLOBAL/banner_thumbnail.jpg`.toLowerCase()}
                imgProps={{ width: "40", height: "40" }}
              />
            }
          >
            Join Our Global Game
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
