import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  Typography,
  Link,
  Drawer,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  Toolbar,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Close as RemoveIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";
import { removeNotification } from "../../store/actions";
import * as consts from "../../consts";
import LazyLoad from "react-lazyload";

export default function NotificationsMenu(props) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      height: "100vh",
    },
    title: {
      fontSize: "2rem",
    },
    notification: {
      margin: "0px",
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      fontSize: "1rem",
    },
    listItem: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getDisplayDate = (date) => {
    let displayDate = `posted on ${date.toDateString()}`;
    let seconds = Math.floor((new Date() - date) / 1000);
    let count = 0;
    let interval = seconds;
    if (interval < 5) {
      displayDate = `just posted`;
    } else {
      if (interval >= 1 && interval < 60) {
        count = Math.floor(interval);
        displayDate = `posted ${count} second${count > 1 ? "s" : ""} ago`;
      } else {
        interval = interval / 60;
        if (interval >= 1 && interval < 60) {
          count = Math.floor(interval);
          displayDate = `posted ${count} minute${count > 1 ? "s" : ""} ago`;
        } else {
          interval = interval / 60;
          if (interval >= 1 && interval < 24) {
            count = Math.floor(interval);
            displayDate = `posted ${count} hour${count > 1 ? "s" : ""} ago`;
          }
        }
      }
    }
    return displayDate;
  };

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      variant="persistent"
      anchor="top"
      open={props.open}
    >
      <Toolbar>
        <IconButton
          aria-label="close notifications"
          onClick={() => {
            props.onSetOpenNotifications(false);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" className={classes.title}>
          Notifications
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {state.notifications.map((notification) => {
          return (
            <LazyLoad once key={notification.id}>
              <Link
                component={NavLink}
                to={`${consts.PANEL_GAME}/${notification.gameID}${consts.PANEL_GAME_FEED}/${notification.clueID}/${notification.teamID}`}
                className={classes.notification}
                activeClassName={classes.selected}
                color="inherit"
              >
                <ListItem classes={{ container: classes.listItem }}>
                  <ListItemAvatar
                    onClick={() => {
                      props.onSetOpenNotifications(false);
                    }}
                  >
                    <Avatar
                      alt=""
                      src={`${consts.BLOB_URL}${notification.gameID}/clues/${notification.gameID}-${notification.clueID}-${notification.teamID}_thumbnail.jpg`.toLowerCase()}
                      imgProps={{ width: "40", height: "40" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    onClick={() => {
                      props.onSetOpenNotifications(false);
                    }}
                    primary={
                      <Typography variant="body1">
                        {notification.note}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        {getDisplayDate(new Date(notification.posted))}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="dismiss notification"
                      onClick={() => {
                        removeNotification(
                          notification.id,
                          getAccessTokenSilently,
                          dispatch
                        );
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Link>
              <Divider />
            </LazyLoad>
          );
        })}
      </List>
    </Drawer>
  );
}
